import React from 'react'
import Box from '@material-ui/core/Box'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import {FrequentlyAskedQuestions, FAQ} from './FAQs'
import {createStyles, makeStyles} from '@material-ui/styles'
import {Theme} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    details: {

    },
    expansionPanel: {
      color: 'white',
      backgroundColor: 'black'
    }
  })
)

const FAQComponent: React.FC = () => {
  const classes = useStyles()
  return (
    <React.Fragment>
      {
        FrequentlyAskedQuestions.map((item: FAQ) => (
          <ExpansionPanel className={classes.expansionPanel} expanded={true}>
            <ExpansionPanelSummary>
              <Box fontWeight={600}>
                {item.question}
              </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              {item.answer}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))
      }
    </React.Fragment>
  )
}

export default FAQComponent
