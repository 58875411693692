import React, {useState} from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import UserTable from './UserTable'
import StyledButton from '../../../components/StyledButton/StyledButton'
import Box from '@material-ui/core/Box'
import AxiosServer from '../../../config/server'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4)
    },
    progress: {
      margin: theme.spacing(1)
    }
  })
)


const ManageUsersView: React.FC = () => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const handleExport = async () => {
    try {
      setIsLoading(true)
      const response = await AxiosServer.get('/admin/users/export', {
        responseType: 'blob',
      })

      // Handle auto download
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'file.csv')
      document.body.appendChild(link)
      link.click()

      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }

  }
  return (
    <div className={classes.root}>
      <Box display='flex' alignItems={'center'}>
        <StyledButton variant='contained' onClick={handleExport}>Export</StyledButton>
        {isLoading && <CircularProgress size={24} className={classes.progress} />}
      </Box>
      <UserTable/>

    </div>
  )
}

export default ManageUsersView
