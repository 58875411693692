import React, {useEffect, useState} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import { useAuthStore } from '../../hooks/useAuth'
import {Link, useHistory} from 'react-router-dom'
import CountryDropdown from './CountryDropdown'
import StateDropdown from './StateDropdown'
import FormControl from '@material-ui/core/FormControl'
import StyledButton from '../../components/StyledButton/StyledButton'
import Box from '@material-ui/core/Box'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormErrorResponse from '../../components/FormError/FormErrorResponse'
import StyledTextField from '../../components/StyledTextField/StyledTextField'
import Typography from '@material-ui/core/Typography'
import StyledCard from '../../components/StyledCard/StyledCard'
import CardContent from '@material-ui/core/CardContent'
import queryString from 'query-string'
import {useLocation} from 'react-router'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      maxWidth: 500,
      margin: 'auto'
    },
    logo: {
      height: 196,
      marginBottom: theme.spacing(4)
    },
    title: {
      marginBottom: theme.spacing(4)
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2),
    },
    progress: {
      margin: theme.spacing(2)
    },
    formControl: {
      marginBottom: theme.spacing(2)
    },
    form: {
      padding: theme.spacing(4)
    }

  })
)
const RegisterView: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const { search } = useLocation()
  const [state, setState] = useState<State>({
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    phone: '',
    state: '',
    country: 'United States',
    terms: false,
    pp: false,
    referralCode: ''
  })

  const [validated, setValidated] = useState<boolean>(false)
  const {register} = useAuthStore()
  const [isLoading, setIsLoading] = useState(false)
  const [formErrors, setFormErrors] = useState<string[]>([])

  useEffect(() => {
    (() => {
      const { ref } = queryString.parse(search)
      if (ref) {
        setState({...state, referralCode: (ref as string) })
      }
    })()
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSelect = (name: string, value: string | unknown) => {
    setState({ ...state, [name]: value })
  }

  const handleCheckBoxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: event.target.checked })
  }


  const validateRegister = () => {
    const errors: string[] = []
    if (state.email.toLowerCase() !== state.confirmEmail.toLowerCase()) errors.push('Emails must match')
    if (state.firstName.length === 0) errors.push('First Name cannot be empty')
    if (state.lastName.length === 0) errors.push('Last Name cannot be empty')
    if (state.phone.length === 0) errors.push('Phone cannot be empty')
    if (state.email.length === 0) errors.push('Email cannot be empty')
    if (state.password.length === 0) errors.push('Password cannot be empty')
    if (state.confirmPassword.length === 0) errors.push('Confirm password cannot be empty')
    if (state.password !== state.confirmPassword) errors.push('Passwords do not match')
    if (state.country.length === 0) errors.push('Country cannot be empty')
    if (state.country === 'United States' && state.state.length === 0) errors.push('State must be selected')
    if (!state.terms) errors.push('Must agree to terms and conditions')
    if (!state.pp) errors.push('Must agree to our private policy')
    setValidated(true)
    return errors
  }

  const handleRegister = async (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const errors = validateRegister()
    if (errors.length !== 0) {
      setFormErrors(errors)
    } else {
      setIsLoading(true)
      try {
        const error = await register(state)
        setIsLoading(false)
        if (error) {
          setFormErrors([error])
          return
        }
        history.push('/subscribe')
      } catch (error) {
        setIsLoading(false)
      }
    }
  }

  return (
    <div className={classes.root}>

      <StyledCard>
        <CardContent>
          <Typography variant='h3'>Register</Typography>
          <form onSubmit={handleRegister} className={classes.form}>
            <StyledTextField
              name='firstName'
              label='First Name'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.firstName}
              variant='outlined'
              error={state.firstName === '' && validated}
              helperText={state.firstName === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='lastName'
              label='Last Name'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.lastName}
              variant='outlined'
              error={state.lastName === '' && validated}
              helperText={state.lastName === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='phone'
              label='Phone'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.phone}
              variant='outlined'
              error={state.phone === '' && validated}
              helperText={state.phone === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='email'
              label='Email'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.email}
              variant='outlined'
              error={state.email === '' && validated}
              helperText={state.email === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='confirmEmail'
              label='Confirm Email Address'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.confirmEmail}
              variant='outlined'
              error={state.confirmEmail === '' && validated}
              helperText={state.confirmEmail === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='password'
              label='Password'
              type='password'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.password}
              variant='outlined'
              error={state.password === '' && validated}
              helperText={state.password === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='confirmPassword'
              label='Confirm Password'
              type='password'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.confirmPassword}
              variant='outlined'
              error={state.confirmPassword === '' && validated}
            />
            {
              state.country === 'United States' && (
                <FormControl error={state.state === '' && validated} variant='outlined' fullWidth className={classes.formControl}>
                  <StateDropdown handleSelect={handleSelect} value={state.state}/>
                  <FormHelperText>{ state.state === '' && validated ? 'Cannot be empty' : '' }</FormHelperText>
                </FormControl>
              )
            }
            <FormControl
              variant='outlined'
              fullWidth
              className={classes.formControl}
              error={state.country === '' && validated}
            >
              <CountryDropdown handleSelect={handleSelect} value={state.country} validated={validated}/>
              <FormHelperText>{ state.country === '' && validated ? 'Cannot be empty' : '' }</FormHelperText>
            </FormControl>

            <FormControl error={!state.terms && validated}>
              <FormControlLabel
                control={
                  <Checkbox checked={state.terms} color='primary' onChange={handleCheckBoxChange('terms')} value="terms" />
                }
                label={<Box fontFamily='Teko'>I agree to the <Link to='/terms-and-conditions'>Terms and Conditions</Link></Box>}
              />
              { (!state.terms && validated) && <FormHelperText style={{color: 'red'}}>You must agree</FormHelperText>}
            </FormControl>
            <FormControl error={!state.pp && validated}>
              <FormControlLabel
                control={
                  <Checkbox checked={state.pp} color='primary' onChange={handleCheckBoxChange('pp')} value="pp" />
                }
                label={<Box fontFamily='Teko'>I agree to the <Link to='/privacy-policy'>Privacy Policy</Link></Box>}

              />
              { (!state.pp && validated) && <FormHelperText>You must agree</FormHelperText>}
            </FormControl>
          </form>
          { formErrors.length > 0 && <Box mt={2}><FormErrorResponse formErrors={formErrors}/></Box> }
          {isLoading && <CircularProgress size={24} className={classes.progress} />}<br />
          <Box my={2}>
            <StyledButton disabled={false} size='large' variant='contained' onClick={handleRegister}>Register</StyledButton>
            <Typography variant='body1'>
              Already have an account? <Link to='/login' className='link-no-style' style={{ textDecoration: 'underline'}}>Login</Link>
            </Typography>
          </Box>
        </CardContent>

      </StyledCard>

    </div>
  )
}

interface State {
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  phone: string;
  state: string;
  country: string;
  terms: boolean;
  pp: boolean;
  referralCode: string;
}

export default RegisterView
