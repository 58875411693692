import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuthStore } from '../../hooks/useAuth'


const PrivateRoute = ({ component, ...rest }: any) => {
  const { isUserLoggedIn } = useAuthStore()
  return (
    <Route
      {...rest}
      render={props =>
        isUserLoggedIn ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
