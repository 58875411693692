import React, { useState } from 'react'
import {Theme} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import {useAuthStore} from '../../hooks/useAuth'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    banner: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '& a': {
        color: 'white',
        textDecoration: 'underline'
      }
    }
  })
)



const NotificationBanner: React.FC = () => {
  const classes = useStyles()
  const { user: { stripeProfile } } = useAuthStore()
  const notifications = []

  if (stripeProfile?.paymentIntentAction === 'requires_payment_method') {
    notifications.push(
      <div className={classes.banner}>
        <Typography variant={'body1'}>Please <Link to={'/account'}>update</Link> your payment method</Typography>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      ${notifications.map((item) => item)}
    </div>
  )
}

export default NotificationBanner
