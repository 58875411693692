import {createStyles, withStyles} from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'

const StyledInputLabel = withStyles(() => createStyles({
  root: {
    fontFamily: 'Helvetica',
    backgroundColor: 'black',
    padding: '0 3px',
  },
}))(InputLabel)

export default StyledInputLabel
