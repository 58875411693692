import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { Theme, createStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/styles'
import Hidden from '@material-ui/core/Hidden'
import { useAuthStore } from '../../hooks/useAuth'

import Home from '@material-ui/icons/Home'
import InsertEmoticon from '@material-ui/icons/InsertEmoticon'

import Edit from '@material-ui/icons/Edit'
import Event from '@material-ui/icons/Event'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import VpnKey from '@material-ui/icons/VpnKey'
import ExitToApp from '@material-ui/icons/ExitToApp'
import CardMembershipIcon from '@material-ui/icons/CardMembership'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Button from '@material-ui/core/Button'
import AdminDropdown from './AdminDropdown'
import AccountCircle from '@material-ui/icons/AccountCircle'
import {Box} from '@material-ui/core'
import {Build, Info, Store} from '@material-ui/icons'


const drawerWidth = 240
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: 1201,
      background: 'black',
      boxShadow: 'none',
      borderBottom: 'none',
      color: 'white',
      height: 120,
      paddingLeft: 120,
      paddingRight: 120,
      [theme.breakpoints.down('md')]: {
        height: 64,
        padding: 0
      },
      justifyContent: 'center',
      alignContent: 'center',
      maxWidth: 1920,
      marginRight: 'auto',
      marginLeft: 'auto'
    },
    title: {
      fontFamily: 'Roboto',
      flexGrow: 1,
      fontSize: 20,
      '& a': {
        display: 'flex',
        alignItems: 'center',
      }
    },
    logo: {
      height: 28,
      marginRight: theme.spacing(1.5)
    },
    link: {
      color: 'white',
      textDecoration: 'none',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      border: 'none',
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    mobileDrawerPaper: {
      width: drawerWidth,
      backgroundColor: 'black'
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: 'black',
      border: 'none',
      paddingLeft: theme.spacing(2),
    },
    toolbar: {
      height: 96
    },
    drawerList: {
      '& a': {
        fontFamily: 'Cubano',
        textDecoration: 'none',
        color: 'inherit'
      }
    },
    listItem: {
      borderRadius: 5,
      letterSpacing: 1,
      fontWeight: 'bolder'
    },
    listItemIcon: {
      minWidth: 44,
      color: 'white'
    },
    rightNavContent: {
      letterSpacing: 1,
      fontSize: 14
    },
    navButton: {
      marginLeft: theme.spacing(1),
      fontWeight: 600,
      letterSpacing: .5,
      color: 'white',
      fontSize: 25
    }
  })
)

interface LinkItem {
  label: string;
  path: string;
  icon: JSX.Element;
  subscriptionOnly?: boolean;
}

const adminLinks: LinkItem[] = [
  { label: 'Manage Giveaways', path: '/admin/manage-giveaways', icon: (<Event />) },
  { label: 'Manage Users', path: '/admin/manage-users', icon: (<SupervisedUserCircleIcon />) },
  { label: 'Manage Entries', path: '/admin/manage-entries', icon: (<SupervisedUserCircleIcon />) },
]

const preLoginLinks: LinkItem[] = [
  { label: 'Home', path: '/', icon: (<Home />) },
  { label: 'Giveaway', path: '/giveaway', icon: (<InsertEmoticon />) },
  // { label: 'Shop', path: '/shop', icon: (<Store />)},
  // { label: 'Service', path: '/service-request', icon: (<Build />)},
  // { label: 'About', path: '/about', icon: (<Info />)},
  { label: 'Login', path: '/login', icon: (<VpnKey />) },
  { label: 'Register', path: '/register', icon: (<Edit />) },
]
const postLoginLinks: LinkItem[] = [
  { label: 'Home', path: '/', icon: (<Home />) },
  { label: 'Giveaway', path: '/giveaway', icon: (<InsertEmoticon />) },
  // { label: 'Shop', path: '/shop', icon: (<Home />)},
  // { label: 'Service', path: '/service-request', icon: (<Build />)},
  { label: 'Subscribe', path: '/subscribe', icon: (<CardMembershipIcon />) },
  { label: 'Account', path: '/account', icon: (<AccountCircle />) },
  { label: 'Logout', path: '/logout', icon: (<ExitToApp />) },
]


const Navbar: React.FC = () => {
  const classes = useStyles()
  const { isAdmin, isUserLoggedIn, user } = useAuthStore()
  const [ mobileOpen, setMobileOpen ] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  // Generates the links
  const generateListItems = (list: LinkItem[]) => (
    list.map(({ label, path, icon}: LinkItem, key: number) => (
      <Link to={path} key={key}>
        <ListItem className={classes.listItem} button onClick={handleDrawerToggle}>
          <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </ListItem>
      </Link>
    ))
  )

  const mobileDrawer = (
    <React.Fragment>
      <List className={classes.drawerList}>
        {isUserLoggedIn ? generateListItems(postLoginLinks) : generateListItems(preLoginLinks)}
      </List>
      {isAdmin && (<List className={classes.drawerList} subheader={<ListSubheader> Admin </ListSubheader>} >
        { generateListItems(adminLinks) }
      </List>)}
    </React.Fragment>
  )

  const generateNavButtons = (list: LinkItem[]) => {
    return (
      list.map( ({ label, path, subscriptionOnly }: LinkItem, key: number) => {
        if ((subscriptionOnly && user.stripeProfile?.active) || !subscriptionOnly) {
          return <Link to={path} key={Math.floor(Math.random()*10000)}><Button className={classes.navButton}>{label}</Button></Link>
        }
      })
    )
  }

  return (
    <React.Fragment>
      <AppBar className={classes.appBar} position={'relative'}>
        <Toolbar>
          <Hidden mdUp>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={classes.title}>
            <Link className={classes.link} to='/'>
              <img alt='upload-preview' className={classes.logo} src='./assets/blockhead-white.png' />
            </Link>
          </div>
          <Hidden smDown>
            { isUserLoggedIn ? generateNavButtons(postLoginLinks) : generateNavButtons(preLoginLinks) }
            { isAdmin && <AdminDropdown links={adminLinks}/> }
          </Hidden>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation='css'>
          <Drawer
            variant='temporary'
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true
            }}
            open={mobileOpen}
            classes={{
              paper: classes.mobileDrawerPaper,
            }}
          >
            {mobileDrawer}
          </Drawer>
        </Hidden>
      </nav>
    </React.Fragment>
  )
}

export default Navbar
