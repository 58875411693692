// React
import React, {useEffect, useState} from 'react'
import {createStyles, makeStyles} from '@material-ui/styles'
import {Theme} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import TitleComponent from '../../components/TextComponents/TitleComponent'
import AxiosServer from '../../config/server'
import {useAuthStore} from '../../hooks/useAuth'
import Typography from '@material-ui/core/Typography'
// Router Packages

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    root: {
      padding: theme.spacing(2)
    }
  })
))

const DiscordAuthorizationView: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const { isUserLoggedIn } = useAuthStore()
  const [message, setMessage] = useState<string>('Your Discord Account is now connected to Blockhead Moto. Open the Discord app and log in to hang out with us!')
  useEffect(() => {
    (async () => {
      if (isUserLoggedIn) {
        const query = queryString.parse(location.search)
        if (query.code) {
          const { data } = await AxiosServer.post('/users/discord', { discordCode: query.code })
          console.log(data)
        } else if (query.error === 'access_denied') {
          setMessage('Discord Account Link Rejected')
        }
      }
    })()
  }, [isUserLoggedIn])

  return (
    <div className={classes.root}>
      <Box py={8} textAlign={'center'}>
        <Typography variant={'h4'}>{ message }</Typography>
      </Box>
    </div>
  )


}

export default DiscordAuthorizationView
