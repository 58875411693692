import {createStyles, Theme, withStyles} from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'

const StyledSelect = withStyles((theme: Theme) => createStyles({
  root: {
    '& option': {
      color: 'black'
    }
  },
  input: {
    fontFamily: 'Helvetica',
    color: 'white',
    border: '1px solid #ced4da',
    height: 61,
    padding: 0,
    paddingLeft: 15,
    transition: theme.transitions.create(['border-color']),
    '&:focus': {
      borderRadius: 5,
      borderColor: theme.palette.primary.main
    },
  }
}))(InputBase)

export default StyledSelect
