// React
import React, { useEffect, useState } from 'react'
import {createStyles, makeStyles} from '@material-ui/styles'
import {Theme} from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import {useAuthStore} from '../../hooks/useAuth'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import constants from '../../utils/constants'
import ProgressBar from '../ProgressBar/ProgressBar'
import {useEntryStore} from '../../hooks/useEntries'
import {EntryDataSchema} from '../../hooks/useEntries/reducer'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import StyledCard from '../StyledCard/StyledCard'
import CardHeader from '@material-ui/core/CardHeader'

// Router Packages

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    root: {
      color: 'white',
      marginBottom: theme.spacing(4)
    },
    progress: {
      margin: theme.spacing(2)
    }
  })
))


const EntriesCard: React.FC = () => {
  const classes = useStyles()
  const { entries, isLoading } = useEntryStore()
  const userEntries = entries as EntryDataSchema[]
  console.log(entries)
  return (
    <div className={classes.root}>
      <StyledCard>
        <CardHeader title={'Your Entries'}/>
        <CardContent>
          { isLoading && <CircularProgress size={24} className={classes.progress} /> }
          {
            userEntries.map(({ totalEntries, monthEntries, type }, key) => {
              const maxEntries = constants.maxEntries[type as string]
              return (
                <Box key={key}>
                  <Typography variant='h4'>Bike Giveaway</Typography>
                  <Typography variant='body2'>{ totalEntries } Entries</Typography>
                  <Box my={2}>
                    <ProgressBar value={(monthEntries / maxEntries) * 100}/>
                    { monthEntries >= maxEntries && (
                      <Box>Max Entries Met</Box>
                    )}
                  </Box>
                </Box>
              )
            })
          }
          <Box>
            {/*{*/}
            {/*  !user.stripeProfile?.isCanceled ? (*/}
            {/*    <Typography variant='caption'>*/}
            {/*      Recently upgraded your plan and don't see your entries? Don't worry, they'll be deposited on <Box fontWeight={600} component={'span'}>{ new Date(user.stripeProfile?.subscriptionPeriodEnd as string).toDateString() }</Box>*/}
            {/*    </Typography>*/}
            {/*  ) : null*/}
            {/*}*/}
          </Box>

        </CardContent>
      </StyledCard>
    </div>
  )
}

export default EntriesCard
