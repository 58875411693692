import React from 'react'
import { useHistory } from 'react-router-dom'
import {Theme} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/styles'
import {SubscriptionTier} from '../../types/Subscription'
import StyledButton from '../StyledButton/StyledButton'
import Box from '@material-ui/core/Box'
import {Testmonial} from '../../types/Testimonial'
import Typography from '@material-ui/core/Typography'

interface IProps {
  testimonial: Testmonial;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '& a': {
      color: '#171717'
    },
    root: {
      margin: theme.spacing(2),
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: '#797979',
      maxWidth: 400,
      [theme.breakpoints.down('md')]: {
        maxWidth: 325
      },
      flex: '0 0 auto'
    },
    body: {
      backgroundColor: '#797979',
      color: 'white',
      padding: `${theme.spacing(0)}px ${theme.spacing(0)}px  ${theme.spacing(6)}px  ${theme.spacing(0)}px`,
    },
    picture: {
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: '100%',
      }
    }
  })
)

const TestimonialCard: React.FC<IProps> = ({ testimonial }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.picture}><img src={testimonial.picture}/></div>
      <div className={classes.body}>
        <Box fontSize={30} fontFamily={'Cubano'} textAlign={'center'} p={2}>
          S{testimonial.season}: {testimonial.bike}
        </Box>
        <Box px={4}>
          <Typography variant={'body1'}>{testimonial.name} | {testimonial.location}</Typography>
        </Box>
        <Box fontSize={20} fontFamily={'Helvetica'} px={4}>
          "{testimonial.body}"
        </Box>
      </div>
    </div>
  )
}

export default TestimonialCard
