import {createStyles, Theme, withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const StyledButton = withStyles((theme: Theme) => createStyles({
  root: {
    fontFamily: 'Cubano',
    backgroundColor: 'rgba(0,0,0,0)',
    fontSize: 25,
    boxShadow: 'none',
    borderRadius: 0,
    border: '4px solid white',
    color: 'white',
    padding: '0.25rem 1.5rem',
    '&:hover': {
      backgroundColor: 'white',
    },
    marginBottom: theme.spacing(2)
  },
}))(Button)

export default StyledButton
