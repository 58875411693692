import {SubscriptionTier} from '../types/Subscription';


export const Tiers: SubscriptionTier[] = [
  {
    name: 'Tier 1',
    id: process.env.REACT_APP_TIER_ONE_KEY,
    giveaway: 'primary',
    price: 500,
    entries: 5,
  },
  {
    name: 'Tier 2',
    id: process.env.REACT_APP_TIER_TWO_KEY,
    giveaway: 'primary',
    price: 1000,
    entries: 10,
  },
  {
    name: 'Tier 3',
    id: process.env.REACT_APP_TIER_THREE_KEY,
    giveaway: 'primary',
    price: 2500,
    entries: 25,
  },
  {
    name: 'Tier 4',
    id: process.env.REACT_APP_TIER_FOUR_KEY,
    giveaway: 'primary',
    price: 5000,
    entries: 50,
  },
  {
    name: 'Tier 5',
    id: process.env.REACT_APP_TIER_FIVE_KEY,
    giveaway: 'primary',
    price: 10000,
    entries: 100,
  },
];
