import React, { useState } from 'react'
import {useAuthStore} from '../../hooks/useAuth'
import Box from '@material-ui/core/Box'
import StyledButton from '../../components/StyledButton/StyledButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import StyledDialog from '../../components/StyledDialog/StyledDialog'
import {CardElement, Elements, injectStripe, StripeProvider} from 'react-stripe-elements'
import {CheckoutStoreProvider} from '../../hooks/useCheckout/context'
import axios from 'axios'
import server from '../../config/server'


const UpdatePaymentMethodComponent: React.FC<any> = ({ stripe }) => {
  const { user, fetchUser } = useAuthStore()
  const { email, stripeProfile } = user
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showingModal, setShowingModal] = useState<boolean>(false)

  const handleSave = async () => {
    try {
      const stripeResponse = await stripe.createPaymentMethod('card', {
        billing_details: { email }
      })

      await server.post('/payments/update-payment-method', {
        paymentMethod: stripeResponse.paymentMethod
      })
      setShowingModal(false)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <StyledButton size='small' onClick={() => setShowingModal(!showingModal)}>Update Payment Method</StyledButton>
      <StyledDialog open={showingModal}>
        <DialogTitle>Update your payment method</DialogTitle>
        <DialogContent  style={{ width: 600, maxWidth: '100vw'}}>
          <>
            <CardElement style={{
              base: {
                iconColor: '#c4f0ff',
                color: '#fff',
                fontWeight: 500,
                fontFamily: 'Helvetica',
                fontSize: '20px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                  color: 'white',
                },
                '::placeholder': {
                  color: '#EFEFEF',
                },
              },
              invalid: {
                iconColor: 'red',
                color: 'red',
              },
            }}/>
          </>
          <Box textAlign={'right'} mt={2}>

            { isLoading ? <CircularProgress size={24} style={{ margin: ' 0px 8px' }}/> : null }
            <StyledButton variant={'contained'} onClick={() => setShowingModal(false)}>Close</StyledButton>
            &emsp;
            <StyledButton variant={'contained'} onClick={handleSave}>Update</StyledButton>

          </Box>


        </DialogContent>
      </StyledDialog>
    </>
  )
}

export default injectStripe(UpdatePaymentMethodComponent)
