import React from 'react'
import {Grid, Theme} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      maxWidth: 1920
    },
    images: {
      '& img': {
        maxWidth: '100%'
      }
    }

  })
)

const AboutView: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box mb={4}>
            <Typography variant='h2'>About<br/>Blockhead</Typography>
            <Typography variant='body2' style={{ fontSize: '16pt'}}>
              The foundation we’ve built ourselves on is simple:<br/>
              <span style={{ fontWeight: 'bolder'}}>WE LOVE MOTORCYCLES!</span> If it’s got two wheels and an engine, chances are, we’ll have fun on it!
              <br/>
              <br/>
              In addition to our love of motorcycles, we also love the community that comes along with it <i>(that’s you!)</i> The Blockhead community started in 2016, and has grown at an amazing rate, and we can’t thank you all enough for your continued support!
              <br/>
              <br/>
              With that growth and success we’ve seen because of the community coming together around us, it only felt right to give back to our community as a thank you for your support. That’s when we started giving away motorcycles!
              <br/>
              <br/>
              From test rides to gear reviews, installs to riding with friends, the Blockhead brand aims to deliver consistent quality content showcasing our experiences within this industry, as well as the community surrounding it. It’s our goal to share and communicate with you all how much fun motorcycles can be, while educating people, inspiring new riders, giving people confidence to wrench on their own rides, or even giving them their first motorcycles.
              <br/>
              <br/>
              <span style={{ fontWeight: 'bolder'}}>Ride safe. Stay vigilant.</span>

            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={classes.images}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <img src={'assets/about/about1.jpg'}/>
            </Grid>
            <Grid item xs={6}>
              <img src={'assets/about/about2.png'}/>
            </Grid>
            <Grid item xs={6}>
              <img src={'assets/about/about3.png'}/>
            </Grid>
            <Grid item xs={6}>
              <img src={'assets/about/about4.png'}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default AboutView
