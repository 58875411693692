// React
import React from 'react'
import ExportEntries from './ExportEntries'
import Grid from '@material-ui/core/Grid'
import {createStyles, makeStyles} from '@material-ui/styles'
import {Theme} from '@material-ui/core'
import CashOutEntries from './CashOutEntries'

interface IState {
  start: string;
  end: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      padding: theme.spacing(6)
    },
  })
)

const ManageEntriesView: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container justify={'center'} spacing={4}>
        <Grid item xs={12} md={6} lg={4}>
          <ExportEntries/>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CashOutEntries/>
        </Grid>
      </Grid>
    </div>
  )
}

export default ManageEntriesView
