import { User } from '../../types/User'

export interface AuthState {
  isAdmin: boolean;
  isUserLoggedIn: boolean;
  user: Partial<User> | User;
}

export type AuthActions = {
  type: 'login' | 'logout' | 'setUser';
  payload?: AuthState;
};

// The user is defined here for autocompletion help downstream.
export const InitialAuthState: AuthState = {
  isUserLoggedIn: false,
  isAdmin: false,
  user: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    isAdmin: false,
    phone: '',
    canParticipate: true
  }
}

export const Reducer = (state: AuthState, action: AuthActions): AuthState => {
  switch (action.type) {
  case 'login': {
    return { ...state, user: action.payload!, isAdmin: action.payload!.isAdmin, isUserLoggedIn: true }
  }
  case 'logout': {
    return InitialAuthState
  }
  case 'setUser': {

    return { ...state, user: action.payload!.user, isAdmin: action.payload!.isAdmin, isUserLoggedIn: true }
  }
  default: {
    return state
  }
  }
}


