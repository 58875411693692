import React, { useState } from 'react'
import {Card, NativeSelect} from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import {useAuthStore} from '../../hooks/useAuth'
import Box from '@material-ui/core/Box'
import StyledButton from '../../components/StyledButton/StyledButton'
import Button from '@material-ui/core/Button'
import AxiosServer from '../../config/server'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import TitleComponent from '../../components/TextComponents/TitleComponent'
import StyledCard from '../../components/StyledCard/StyledCard'
import Typography from '@material-ui/core/Typography'
import PlanSelector from '../../components/Stripe/PlanSelector'
import Grid from '@material-ui/core/Grid'
import {SubscriptionTier} from '../../types/Subscription'
import {Tiers} from '../../data/Tiers'
import StyledSelect from '../../components/StyledSelect/StyledSelect'
import PlanTotal from '../PlanTotal/PlanTotal'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import StyledDialog from '../StyledDialog/StyledDialog'


const SubscriptionStatusCard: React.FC = () => {
  const { user, fetchUser } = useAuthStore()
  const stripeProfile = user.stripeProfile
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [plans, setPlans] = useState<SubscriptionTier[]>(Tiers.filter((element) => user.stripeProfile?.plans?.includes(element.id)) || [])
  const [showingModal, setShowingModal] = useState<boolean>(false)
  const [upcomingPlans] = useState<SubscriptionTier[]>(Tiers.filter((element) => user.stripeProfile?.upcomingPlans?.includes(element.id)) || [])

  const addPlan = (tier: SubscriptionTier) => {
    let newPlans: SubscriptionTier[] = []
    const indexOfSameType = plans.findIndex(x => x.giveaway === tier.giveaway)
    if (indexOfSameType === -1) {
      newPlans = newPlans.concat(plans, tier)
    } else {
      newPlans = [...plans]
      newPlans.splice(indexOfSameType, 1, tier)
    }
    setPlans(newPlans)
  }


  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedTier = Tiers.find(x => x.id === event.target.value) as SubscriptionTier
    addPlan(selectedTier)
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)
      await AxiosServer.post('/payments/update-plan', {
        plans: plans.map(plan => plan.id)
      })
      await fetchUser()
      setShowingModal(false)
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)

  }


  return (
    <>
      <StyledButton onClick={() => setShowingModal(!showingModal)}>Change Plans</StyledButton>
      <Box>
        <Typography variant={'body1'}>Upcoming Plans</Typography>
        <Typography variant={'caption'}>Starting next billing cycle you will be on { upcomingPlans?.map((element) => element.name)}</Typography>
      </Box>
      <StyledDialog open={showingModal}>
        <DialogTitle>Update your plan</DialogTitle>
        <DialogContent  style={{ width: 600, maxWidth: '100vw'}}>
          <>
            <NativeSelect
              fullWidth
              variant='outlined'
              value={''}
              onChange={handleChange}
              inputProps={{
                name: 'subscriptionPlan',
                id: 'subscriptionPlan'
              }}
              input={<StyledSelect/>}
            >
              <option value={''}>Select Plan</option>
              {
                Tiers.map(tier => <option value={tier.id} key={tier.id}>{`${tier.name}`}</option> )
              }
            </NativeSelect>
            <Box marginTop={2}>
              <PlanTotal selectedPlans={plans}/>
            </Box>
          </>
          <Box textAlign={'right'} mt={2}>

            { isLoading ? <CircularProgress size={24} style={{ margin: ' 0px 8px' }}/> : null }
            <StyledButton variant={'contained'} onClick={() => setShowingModal(false)}>Close</StyledButton>
            &emsp;
            <StyledButton variant={'contained'} onClick={handleSave}>Change Plan</StyledButton>

          </Box>


        </DialogContent>
      </StyledDialog>
    </>
  )
}

export default SubscriptionStatusCard
