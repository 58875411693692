export const terms = [
  {
    title: '1. Acceptance of These Terms',
    body: `Welcome! This website, www.blockheadmoto.com (the "Site," including all subdomains), is a copyrighted work belonging to Blockhead Moto ("we," "us," and "our"). These terms of service (the "Terms") govern your access to and use of the services available on this Site (the " Services"). Specific features of the Site may have additional rules and terms associated with them, and such additional rules and terms will be prominently posted or otherwise made available to each user ("you" and "your," including any entity you represent) and are incorporated herein by reference.
PLEASE READ THESE TERMS CAREFULLY. THESE TERMS CONSTITUTE A BINDING LEGAL AGREEMENT BETWEEN YOU AND BLOCKHEAD MOTO. BY USING THIS SITE AND THE RELATED SERVICES YOU UNCONDITIONALLY AGREE TO BE BOUND BY THESE TERMS, INCLUDING ALL EXCLUSIONS AND LIMITATIONS OF LIABILITY HEREIN, AND WARRANT THAT YOU HAVE FULL AUTHORITY AND CAPACITY, LEGAL AND OTHERWISE, TO USE THE SERVICES. YOU MAY NOT ACCESS OR USE THIS SITE OR THE SERVICES IF YOU DO NOT AGREE TO ANY PART OF THESE TERMS.
THESE TERMS LIMIT THE REMEDIES AVAILABLE IN THE EVENT OF A DISPUTE AND REQUIRE THAT ALL DISPUTES BE RESOLVED THROUGH BINDING ARBITRATION. THIS MEANS THAT YOU AGREE TO RESOLVE DISPUTES INDIVIDUALLY ACCORDING TO THE ARBITRATION PROVISIONS BELOW RATHER THAN IN A COURT OF LAW OR THROUGH A CLASS ACTION LAWSUIT.
You must be at least 18 [eighteen] years of age to use this Site. By using this Site you represent that you are at least 18 [eighteen] years of age.`
  },
  {
    title: '2. License to Use Website',
    body: 'This Site primarily operates as a(n) for-profit business website. Subject to these Terms, you are granted a personal, worldwide, non-transferable, non-exclusive, royalty-free, limited license to use and access the Site. You must use this license only in the manner permitted by these Terms for the sole purpose of using and enjoying the benefit of the Services provided by the Site.'
  },
  {
    title: '3. Advertisements',
    body: 'The Services provided may include advertisements, some of which may be targeted based on the type of information on the Site, queries made, or other information, whether submitted by you or others. The types and amount of advertising on the Site or through the Services is subject to change.'
  },
  {
    title: '4. Cookies',
    body: `This website uses cookies. Cookies store information related to visitors' preferences, browser type, and other information in order to optimize their experiences. By using this Site you acknowledge your understanding of and consent to Blockhead Moto's use of cookies. You may delete cookies already on your computer by consulting the instructions for your file management software. You may also disable future cookies in your browser settings. Please note, however, that disabling cookies may prevent or impair your ability to access and fully make use of certain Services and areas of the Site.
You also acknowledge that websites such as Google and other third-party vendors to our Site may use DART cookies to provide add-ons to our Site based upon visitors visiting our Site and other websites. Visitors may decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL: http://www.google.com/privacy_ads.html.`
  },
  {
    title: '5. Acceptable Use Policy',
    body: `By using this Site you agree to use the Services in compliance with these Terms and all applicable rules and regulations, including the local, state, national, and international laws that apply to your jurisdiction. We are based in Florida, United States. This Site is meant for use by persons within the United States. It may be illegal for certain persons to use the Site in some countries. We make no claim that the Site will be legal or accessible outside of the United States. Subject to these Terms, you may view, print, and use our content and Services as permitted on the Site and only for your own personal, non-commercial use. You further agree and acknowledge that your use of this Site and the Services results in you sending electronic interstate transmissions through our computer network, and such transmissions may include, but are not limited to, any searches, file uploads, posts, instant messages, or emails.
The following uses of the Site are prohibited unless we have intended to provide you with access or have provided you express written permission to the contrary:
a. Using the Site in a manner that causes, or may cause, damage to the Site or in a way that impairs visitors' abilities to access or use the Site and Services, including using the Site in a manner or for a purpose that is unlawful or fraudulent;
 
b. Using the Site in order to copy, store, upload, publish, use, transmit, host, or distribute anything consisting of or linked to any computer virus, spyware, rootkit, worm, keystroke logger, or other malicious computer software or software that may destroy, damage, or alter a computer system;
c. Conducting any systematic or automated data collection on or related to the Site or its users without their consent, including, without limitation, data mining, data extraction, scraping, data harvesting, "framing," or article "spinning";
d. Using the Site to collect, harvest, or compile information or data regarding other users of the Site without their consent;
e. Using software or automated agents or scripts to generate automated searches, requests, or queries on the Site or to mine data from the Site, provided, however, that operators of public search engines have our revocable permission to copy materials from the Site for the sole purpose of and only to the extent necessary for creating public search indices, but not caches or archives of such materials, according to the parameters in our robots.txt file;
f. Transmitting or sending unsolicited communications, commercial or otherwise, or conducting any marketing activities, including using the Site to send spam, pyramid schemes, or chain letters;
g. Republishing (including on another website), duplicating, copying, redistributing, selling, leasing, transferring, hosting, renting, or licensing any part of the Site or otherwise commercially exploiting content, whether in whole or in part, on the Site;
h. Editing, modifying, making derivative works of, reverse engineering, or reverse compiling any information, content, systems, or Services on the Site;
i. Accessing the Site in order to create a similar or competitive business, product, service, or website;
j. Collecting, transmitting, distributing, uploading, or displaying any content or otherwise using the Site in a manner that (i) violates the rights of any third party, including any intellectual property rights or rights to privacy, (ii) is unlawful, tortious, threatening, vulgar, defamatory or libelous, pornographic, obscene, patently offensive, racist, or promotes physical harm or injury, (iii) causes or may cause harm to minors in any way, or (iv) impersonates another individual or organization or otherwise misrepresents affiliation with another person or entity without permission;

k. Interfering with, disrupting, or overburdening servers or networks connected to the Site;
l. Gaining or attempting to gain unauthorized access by any means to any part of the Site or to computers or networks connected to the Site; and
m. Harassing, stalking, harming, or otherwise interfering with or negatively affecting another user's normal use and enjoyment of the Site.`
  },
  {
    title: '6. International Use and Compliance',
    body: 'Due to the global nature of the internet, you understand and agree that it is your responsibility to ensure that your use of the Site complies with all local, international, and other laws that may apply. In addition, United States import and export control laws and the import regulations of other countries may apply to the use of the Site. You agree not to export, upload, post, or transfer, directly or indirectly, any software, technical data, or technology acquired through us, the Site, or the Services in violation of such export or import laws, including, but not limited to, the United States Export Administration Regulations (EAR) and the various United States sanctions programs.'
  },
  {
    title: '7. Minors',
    body: 'We do not knowingly collect personal information or data from visitors under 13 years of age. You may not use this site if you are under the age of 13 [thirteen].'
  },
  {
    title: '8. User Accounts',
    body: `Visitors to the Site are permitted to create user accounts or profiles. Upon registering for our Services, you are considered a "Member" of the Site. You represent that all information you submit when creating an account is true and accurate, and you promise to update and maintain the accuracy of this information at all times. Each Member is the sole authorized user of his or her account. Members are responsible for keeping their passwords and account access information confidential. Therefore, you should take measures to restrict access to your account and to any devices from which you access your account. As a Member, you are responsible for all activities that occur under your account, and you acknowledge that Blockhead Moto is not responsible for unauthorized access to your account that results from theft or misappropriation of your account or password. Members are prohibited from assigning or otherwise transferring their accounts and passwords to others. You must notify us immediately if you know of or suspect that an unauthorized use or other breach of security of your account or the Site has occurred. We may not be held liable for any loss or damage that may arise from your failure to maintain the security of your account. You may delete your account at any time and for any reason by following the
instructions on the Site. We retain the right to suspend or terminate your account at any time and for any reason, as further detailed below.`
  },
  {
    title: '9. Enforcement of Acceptable Use Policy',
    body: 'We reserve the right to review and investigate your use of the Site and to take any appropriate action against you that we determine is necessary in our sole discretion should you violate these Terms or otherwise create liability, loss, or damage for us, our Site visitors, or a third party. Such action may include, but is not limited to, restricting your account privileges or terminating your account, initiating proceedings to recover any losses and reporting you to law enforcement authorities.'
  },
  {
    title: '10. Third-Party Resources',
    body: 'The Site may contain links and advertisements to third-party websites and services (collectively, "Third-Party Resources"). You agree and acknowledge that Third-Party Resources are not under our control and we are not responsible for the content, products, or services they provide. We provide access to Third-Party Resources only as a convenience to you and do not monitor, endorse, warrant, or make any representations regarding Third-Party Resources. It is your sole responsibility to exercise appropriate caution and discretion when using Third-Party Resources, and you acknowledge that you assume all risks that arise from such use.'
  },
  {
    title: '11. Modification of Site',
    body: 'We reserve the right to modify, add to, suspend, or terminate all or part of the Site or Services at any time with or without providing prior notice to you. This includes the right to create limits on our visitors\' use of Services and data storage. You agree that we are not liable for using or enforcing the rights stated in this paragraph. Unless we indicate otherwise, any future modifications to the Site are subject to these Terms.'
  },
  {
    title: '12. Support or Maintenance',
    body: 'Although we may choose to provide customer support or website maintenance, you acknowledge and agree that we are under no obligation to do so.'
  },
  {
    title: '13. Restricted Access',
    body: 'Some parts of the Site may be restricted to certain visitors. If you have permission to access restricted parts of the Site, you agree to not share your access information and password with third parties. We may change the restricted parts of the Site from time to time. If you do not have access to restricted parts of the Site, you agree not to use another user\'s account to gain such access or otherwise attempt to gain improper access to the restricted parts of the Site.'
  },
  {
    title: '14. Privacy',
    body: `We manage the collection, use, and security of your personal information according to our Privacy Policy, incorporated herein by reference. By using this Site you consent to our collection and use of your personal information as set forth in the Privacy Policy.
At times we may need to send you communications related to the Site or the Services. Such communications are considered part of the Services, and you may not be able to opt out of receiving them.
We reserve the right to access and disclose the information you submit to the Site if required to do so by law or if we have a reasonable, good-faith belief that doing so is necessary for (i) responding to requests for customer service, (ii) addressing fraud, security, or technical issues, (iii) protecting the rights, property, and safety of Blockhead Moto, its users, and the public, (iv) responding to legal claims and processes, or (v) enforcing these Terms, including investigating potential violations.`
  },
  {
    title: '15. Blockhead Moto Rights and Ownership',
    body: 'Unless otherwise stated, Blockhead Moto and its permitted licensors own all intellectual property rights in the Site and its contents. These rights include, but are not limited to, ownership of all text, graphics, images, logos, copyrighted material, trademarks, patents, software, and other distinctive brand features displayed on the Site or Services, including the compilation of any of the foregoing items. Except for the limited rights granted by these Terms, neither these Terms nor your use or access to the Site give you or any third party any intellectual property rights. We reserve all rights not explicitly granted by these Terms, which do not grant any implied licenses. All copyrights, trademarks, and other intellectual property notices on the Site or Services must be retained on all copies thereof. You may not publish, reverse engineer, modify, distribute, transmit, sell, create derivative or plagiaristic works of, or use or exploit for any commercial reason, whether in whole or in part, any of the content on the Site or Services without our express prior written consent or the consent of any third-party owners of the content. Site content is not for resale under any circumstances.'
  },
  {
    title: '16. Copyright Policy',
    body: `We expect you to respect the intellectual property rights of others when using the Site and Services. We will respond to any notices of copyright infringement that we receive that comply with applicable law and are properly submitted to us (pursuant to 17 U.S.C. 512(c)). In order to submit a notice of infringement of your copyrighted material, please provide us with the
following information:
a. A physical or electronic signature of the copyright owner or a person authorized to act on their behalf;
b. Identification of the copyrighted work or works claimed to have been infringed;
c. Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;
d. Your contact information, including your address, telephone number, and an email address, if available;
e. A statement by you that you have a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and
f. A statement that the information in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.
We may remove any content that is alleged to be infringing at our sole discretion without prior notice and without liability to you. We also reserve the right to take other appropriate action against infringers, such as terminating the user's account if the user is determined to be a repeat infringer. Please send any notice of alleged copyright infringement to our designated copyright agent at the following address:
Blockhead Moto
Attn: Copyright Agent
PO Box 771077
Winter Garden, Florida 33777
Email: info@blockheadmoto.com
Note that pursuant to 17 U.S.C. 512(f), any material misrepresentation in a written notification that content is infringing or that allegedly infringing content was removed by mistake or misidentification automatically subjects the complaining party to liability for damages, including costs and attorney's fees incurred by the alleged infringer, by any copyright owner or copyright owner's authorized licensee, or by us, if injured by such misrepresentation.`
  },
  {
    title: '17. Sales Policies',
    body: `MONTHLY SUBSCRIPTION: By purchasing a Monthly Subscription, you agree to an initial and recurring Monthly Subscription fee that auto renews at the then-current Monthly Subscription rate, and you accept responsibility for all recurring charges until you cancel your subscription. You may cancel your Monthly Subscription at any time, subject to the terms of our cancellation policy.

AUTOMATIC MONTHLY RENEWAL TERMS: Once you subscribe, Blockhead Moto will automatically process your Monthly Subscription fee in the next billing cycle. Blockhead Moto will continue to automatically process your Monthly Subscription fee each month at the then-current Monthly Subscription rate, until you cancel your subscription. We explain how to cancel your Monthly Subscription below at the Section "Cancellation Policy".

If we do not receive payment from your credit card provider, or if your credit card is expired or rejected, you will have a grace period of 3 days to update your billing information. Afterwards, your subscription will expire and you will lose access to all exclusive perks, including, but not limited to, access to the Discord server.

BLOCKHEAD MOTO SWEEPSTAKES: No purchase necessary. We may host sweepstakes and giveaways from time to time through our website. The sweepstakes are governed by their own Official Rules that can be found under Giveaway/Official Rules. Purchase of a subscription does not increase a customer's odds of winning the sweepstakes. MONTHLY SUBSCRIPTIONS DO NOT EXPIRE AT THE END OF A SWEEPSTAKES PERIOD. Your Monthly Subscription will continue to auto-renew after a winner(s) is/are announced until you choose to cancel it. We explain how to cancel your Monthly Subscription below at the Section "Cancellation Policy".`
  },
  {
    title: '18. Cancellation Policy',
    body: `CANCELLATION POLICY: You may cancel your Monthly Subscription at any time by logging into your account, clicking on the Account tab, and following the cancellation procedures described there. If you need help, feel free to contact us at info@blockheadmoto.com. MONTHLY SUBSCRIPTIONS DO NOT EXPIRE AT THE END OF A SWEEPSTAKES PERIOD. Your Monthly Subscription will continue to auto-renew after a winner(s) is/are announced until you choose to cancel it.

You must cancel your subscription prior at least two business days prior to your subscription's auto-renewal date in order to not be charged for the new month's subscription.

REFUND POLICY: NO REFUNDS. We do not offer refunds for any reason. This includes, but is not limited to, the customer forgetting they purchased a subscription, or the customer not realizing that the subscription is still active. MONTHLY SUBSCRIPTIONS DO NOT EXPIRE AT THE END OF A SWEEPSTAKES PERIOD. Your Monthly Subscription will continue to auto-renew after a winner(s) is/are announced until you choose to cancel it.`
  },
  {
    title: '19. Disclaimers',
    body: `THIS SECTION ONLY APPLIES TO THE MAXIMUM EXTENT PERMITTED BY
APPLICABLE LAW. THE SITE IS PROVIDED TO YOU ON AN "AS-IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY REPRESENTATIONS OR WARRANTIES WITH REGARD TO THE CONTENT PROVIDED ON THE SITE. WE, OUR SUBSIDIARIES, OFFICERS, EMPLOYEES, CONTRACTORS, AFFILIATES, PARTNERS, SUPPLIERS, AGENTS, AND LICENSORS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. THIS INCLUDES, WITHOUT LIMITATION TO THE FOREGOING, NO WARRANTY THAT THE SITE, ITS CONTENT, OR THE SERVICES WILL BE CONSTANTLY AVAILABLE OR AVAILABLE AT ALL, UNINTERRUPTED, USEFUL, TRUE, ACCURATE, NON-MISLEADING, TIMELY, RELIABLE, COMPLETE, ERROR-FREE, FREE OF OMISSIONS, SECURE, FREE OF VIRUSES OR OTHER HARMFUL CODE, LEGAL, OR SAFE. NO INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM US OR THROUGH THE SITE OR SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
YOUR USE OF THIS SITE AND THE SERVICES IS AT YOUR SOLE RISK AND WE ASSUME NO RESPONSIBILITY FOR HARM TO YOUR COMPUTER SYSTEM, LOSS OF DATA, THE DELETION OF INFORMATION YOU TRANSMIT ON THE SITE, OR THE DELETION OR FAILURE TO STORE OR TRANSMIT USER CONTENT OR PERSONALIZATION SETTINGS THAT MAY RESULT FROM YOUR ACCESS TO OR USE OF THE SITE AND SERVICES. YOU HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION THAT MAY CAUSE DAMAGE TO YOUR COMPUTER OR INTERNET ACCESS.
THIS SITE AND ITS CONTENTS ARE PROVIDED FOR INFORMATIONAL PURPOSES ONLY. NOTHING ON THIS SITE CONSTITUTES, IS MEANT TO CONSTITUTE, OR MAY BE USED AS ADVICE OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LEGAL, FINANCIAL (INCLUDING TRADING OR INVESTMENT PURPOSES), OR MEDICAL ADVICE. WE ENCOURAGE YOU TO CONSULT THE APPROPRIATE PROFESSIONAL SHOULD YOU REQUIRE LEGAL, FINANCIAL, MEDICAL, OR OTHER PROFESSIONAL ADVICE.
IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.
SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU. NOTHING IN THIS SECTION IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE

WHICH MAY NOT BE LAWFULLY LIMITED.`
  },
  {
    title: '20. Limitation of Liability',
    body: `TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, BLOCKHEAD MOTO, INCLUDING ITS SUBSIDIARIES, OFFICERS, EMPLOYEES, CONTRACTORS, AFFILIATES, PARTNERS, SUPPLIERS, AGENTS, AND LICENSORS, MAY NOT BE HELD LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (ii) THE COST OF PROCURING SUBSTITUTE GOODS OR SERVICES; (iii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (iv) ANY CONTENT OBTAINED FROM THE SERVICES; (v) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT; OR (vi) OTHER MATTERS RELATED TO THE SITE OR SERVICES. THESE LIMITATIONS APPLY EVEN IF WE HAVE BEEN EXPRESSLY ADVISED OF THE POTENTIAL LOSS OR LIABILITY.
NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT MAY OUR AGGREGATE LIABILITY EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS (U.S. $100.00) OR THE AMOUNT YOU PAID US, IF ANY, IN THE PAST SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR AFFILIATES, PARTNERS, SUPPLIERS, AGENTS, AND LICENSORS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO YOUR USE OF THE SITE OR SERVICES. YOU FURTHER AGREE NOT TO BRING ANY CLAIM PERSONALLY AGAINST OUR SUBSIDIARIES, OFFICERS, EMPLOYEES, CONTRACTORS, AGENTS, AFFILIATES, PARTNERS, SUPPLIERS, OR LICENSORS.
THE LIMITATIONS IN THIS SECTION APPLY REGARDLESS OF THE THEORY OF LIABILITY, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), WARRANTY, STATUTE, OR OTHERWISE.
NOTE THAT SOME JURISDICTIONS PROHIBIT THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, AND THEREFORE THE LIMITATIONS AND EXCLUSION ABOVE MAY NOT APPLY TO YOU. THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.`
  },
  {
    title: '21. Indemnity',
    body: 'You agree to indemnify, defend, and hold harmless Blockhead Moto and its subsidiaries, officers, employees, contractors, agents, affiliates, partners, suppliers, and licensors, including, but not limited to, costs and attorney\'s fees, from any claim or disputes by a third party arising out of your use of the Site, violation of these Terms, violation of applicable law, or your posting, modifying, or otherwise transmitting content through the Site or Services. We reserve the right, at your expense, to assume exclusive control over the defense of any claim or dispute for which you must indemnify us. You agree to cooperate fully with us in defending such claims or disputes, and you agree not to settle any such claims or disputes without our prior written consent. We will make a reasonable effort to provide you with notice of any such claim or dispute once we receive notice.'
  },
  {
    title: '22. Release',
    body: 'In the event you have a dispute arising out of, directly or indirectly, these Terms or your use of the Site or Services, you hereby release and forever discharge us, including our subsidiaries, officers, employees, contractors, agents, affiliates, partners, suppliers, and licensors, from all related claims, actions, demands, disputes, damages, and liabilities, whether past, present, or future. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR."'
  },
  {
    title: '23. Termination',
    body: 'We may suspend or terminate your access to the Site at any time and for any reason or no reason at all, with or without notice, at our sole discretion. This may result in deletion of information associated with your account. You may also terminate your account by deactivating it or by submitting a termination request and discontinuing your use of the Services. Your account may be deactivated if it experiences a prolonged period of inactivity. Where applicable, all rights and responsibilities of the parties under these Terms will survive the termination of this agreement, including, without limitation, intellectual property ownership, warranties, disclaimers, and limitations of liability.'
  },
  {
    title: '24. Modification of These Terms',
    body: 'We may modify or update these Terms from time to time at our sole discretion. All updates will be effective from the time and date that they are posted. We recommend checking this page regularly for any updates. Your continued use of the Site and Services signifies your acceptance of the updates that occur. We may send you notice of updates to these Terms, including, but not limited to, by email, posting on the Site, or other reasonable means.'
  },
  {
    title: '25. Violations of These Terms',
    body: `Unless both parties agree otherwise, you and Blockhead Moto agree that any dispute, claim, or controversy you may have arising out of or relating to the Site, Services, or these Terms will be resolved through mandatory binding arbitration administered by the American Arbitration Association (AAA) in accordance with its Commercial Arbitration Rules, and the judgment of its arbitrator(s) may be entered by any court of competent jurisdiction. You further agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of this provision, and THE PARTIES FURTHER AGREE THAT EACH IS WAIVING ITS CONSTITUTIONAL RIGHT TO A TRIAL BY JURY AS WELL AS THE RIGHT TO BRING OR PARTICIPATE IN A CLASS ACTION OR MULTI-PARTY ACTION. ALL CLAIMS AND DISPUTES RELATED TO YOUR USE OF THE SITE OR SERVICES OR ARISING UNDER THE TERMS MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. ANY DISPUTE, CLAIM, OR CAUSE OF ACTION THAT YOU MAY HAVE ARISING OUT OF OR RELATING TO THE SITE, SERVICES, OR THESE TERMS MUST BE COMMENCED WITHIN ONE YEAR AFTER THE CAUSE ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION WILL BE PERMANENTLY BARRED. This provision will survive the termination of this agreement and the termination of your account.
You and Blockhead Moto agree that we may seek injunctive or other appropriate relief in the appropriate state or federal court should you violate or threaten to violate the intellectual property rights of us or our subsidiaries, affiliates, partners, suppliers, or licensors, and you consent to exclusive jurisdiction and venue in such courts.`
  },
  {
    title: '27. General Terms',
    body: `a. Entire Agreement
These Terms and our Privacy Policy represent the entire and exclusive agreement between you and Blockhead Moto regarding your use of the Site and Services, superseding and replacing all previous agreements. You may also be subject to additional terms and conditions or separate agreements regarding specific Services we provide, partner or affiliate services, use of Third-Party Resources, or any purchases you may
make through the Site.
In the event that these Terms are translated into other languages and there is a discrepancy between the two language versions, the English language version will prevail in all cases to the extent that such discrepancy is the result of an error in translation.
b. Waiver and Severability
Our failure to enforce any right or provision of these Terms will not operate as a waiver of such right or provision.
If any provision of these Terms or the application thereof is held to be invalid or unenforceable for any reason and to any extent, that provision will be considered removed from these Terms; however, the remaining provisions will continue to be valid and enforceable according to the intentions of the Parties and to the maximum extent permitted by law. If it is held that any provision of these Terms is invalid or unenforceable, but that by limiting such provision it would become valid and enforceable, then such provision will be deemed to be written, construed, and enforced as so limited.
c. Assignment
Your rights and obligations under these Terms, including any accounts, profiles, or personalization settings you may have, may not be assigned, subcontracted, delegated, or otherwise transferred by you without our prior written consent, and any attempt to do so will be null and void. We may freely assign these Terms and our rights and obligations hereunder without notice to you, and these Terms will continue to be binding on assignees.
d. Cumulative Rights
The rights of all parties under these Terms are cumulative and will not be construed as exclusive of each other unless otherwise required by law.
e. Law and Jurisdiction
These terms shall be governed by, and will be construed under, the laws of Florida, United States, without regard to or application of its conflict of law principles or your state or country of residence. All claims, disputes, and legal proceedings related to or arising out of these Terms or your use of the Site or Services will be brought exclusively in the federal or state courts located in Florida, and you hereby consent to and waive any objection of inconvenient forum as to such jurisdiction.`
  },
  {
    title: '28. Electronic Communications',
    body: 'Electronic communications occur whenever you use the Site or Services, you send us emails, we send you emails, and we post notices on the Site. You consent to receive such electronic communications and agree that the electronic communications, including, without limitation, all notices, terms, disclosures, and agreements, has the same legal effect and satisfy any legal requirement that such communications would satisfy if provided to you in a written hardcopy.'
  },
  {
    title: '29. Feedback and Complaints',
    body: `
You hereby assign to us all rights in any feedback or complaints you provide us concerning the Site or Services and agree that we have the right to use and fully exploit all such feedback or complaints in any manner we wish, commercial or otherwise. We will treat all such feedback or complaints as non-confidential and non-proprietary. Do not provide us with any feedback or complaints that you consider confidential or proprietary.
If you are a California resident, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Product of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814 or by telephone at (800) 952- 5210.`
  },
  {
    title: '30. Contact Information',
    body: `Please report any violations of these Terms to us. If you require any more information or have any questions about these Terms, you may contact us as follows:
    
    Blockhead Moto
    PO Box 771077
    Winter Garden, Florida 33777
    Email: info@blockheadmoto.com`
  }
];

export const termsInstructions = [
  {
    body: `All websites need to post their website terms of use in order to notify visitors of its rules and protect themselves from a wide variety of potential legal liabilities. The following information explains the main sections and key considerations of website terms of use.
Note that this document is meant for websites based within the United States. It may not be appropriate for sites hosted in other countries or may need additional language.`
  },
  {
    title: 'Acceptance of Terms',
    body: `This section explains that, by using the website and any services provided, the visitor agrees to abide by the terms and conditions. These terms are a binding legal agreement. Also, the section specifies any age restrictions you impose on visitors.
Browsewrap vs. Clickwrap
"Browsewrap" is the term used for websites that allow users to accept their terms and conditions simply by navigating through the site. "Clickwrap," on the other hand, refers to sites that require visitors to affirmatively agree to the terms by clicking an "Accept" button, or checking a similar box, before creating an account or making a purchase.
Browsewrap is typically only used for static sites that are primarily informational in nature with little to no interaction with the site or others and do not offer products or services for sale. Clickwrap is recommended for all sites that go beyond browsewrap by selling products and services or allowing users to post content or interact with others.
If you choose to implement clickwrap, you should be sure that your method requires visitors to make an affirmative action assenting to the terms and conditions. Best practice is to require visitors to scroll through the entire terms (and privacy policy, if you have one) before they can click the button or box to agree.`
  },
  {
    title: 'License to Use',
    body: 'Visitors are granted the limited right to use the website so long as they conform to the requirements set out in the terms.'
  },
  {
    title: 'Advertisements',
    body: 'You, as the website operator, have the right to advertisements, some of which may be targeted based on the type of information on the site, queries made, or other information submitted by visitors. The types and amount of advertising on the site is subject to change.'
  },
  {
    title: 'Cookies',
    body: 'This section explains that your website uses cookies, which store information related to visitors\' preferences, browser type, and other information in order to optimize their experiences. Visitors may choose to decline cookies by disabling them in their browser settings.'
  },
  {
    title: 'Acceptable Use Policy',
    body: `
      This section explains your rules for conduct and provides a lengthy list of prohibited uses of the website, including using the website
      1. for illegal purposes,
      2. to transmit viruses and other malicious computer software,
      3. to harvest data,
      4. to transmit or send unsolicited communications,
      5. to commercially exploit its content,
      6. to attempt to gain unauthorized access to restricted parts of the site, and
      7. to harass, stalk, harm, or otherwise interfere with another user's normal use and enjoyment of the site.
    `
  },
  {
    title: 'International Use and Compliance',
    body: 'Visitors are responsible for ensuring that their use of the site conforms to all local, international, and other laws that may apply.'
  },
  {
    title: 'Minors and the Children\'s Online Privacy Protection Act',
    body: 'This section, if included, explains whether you knowingly collect, or allow others to collect, personal information or data from visitors under 13 years of age and, if so, provides your reasons for doing so. Website operators that collect this information are required to comply with the Children\'s Online Privacy Protection Act (COPPA), which includes obtaining verifiable parental consent to your collection and use of data pertaining to children.'
  },
  {
    title: 'User Accounts',
    body: 'This section is included if you allow visitors to the website to create user accounts or profiles. Those that do so are called "Members." By creating an account, each Member agrees that all information submitted when creating an account is true and accurate and promises to update and maintain the accuracy of this information at all times. Each Member is the sole authorized user of his or her account and is responsible for keeping passwords and account access information confidential.'
  },
  {
    title: 'User Content',
    body: 'This section is included if you indicated that visitors can submit content on the site. After providing a definition of such "User Content," the section explains that you do not monitor, support, or endorse the submission or publication of any particular User Content and, therefore, may not be held responsible for it. You may remove User Content at any time and for any reason, without notice.'
  },
  {
    title: 'User Content License',
    body: 'Visitors maintain ownership of all User Content submitted and grant you a license to use, publish, copy, and modify the content as you choose.'
  },
  {
    title: 'Enforcement of Acceptable Use Policy',
    body: 'You may review and investigate visitors\' use of the site and take any appropriate action against them that you determine necessary in your sole discretion.'
  },
  {
    title: 'Third-Party Resources',
    body: 'Here, you explain that the site may contain links and advertisements to third-party websites and services, called "Third-Party Resources." Visitors agree that Third-Party Resources are not under your control and that you are not responsible for the content, products, or services they provide.'
  },
  {
    title: 'Modification of Site',
    body: 'In this section, you reserve the right to modify, add to, suspend, or terminate all or part of the site or its services at any time with or without providing prior notice to visitors.'
  },
  {
    title: 'Support or Maintenance',
    body: 'Here, visitors are told that you are under no obligation to provide customer support or website maintenance.'
  },
  {
    title: 'Restricted Access',
    body: 'Visitors agree not to use the site to gain improper access to restricted parts. If you provide visitors with account access to areas of the site that are restricted to the general public, such visitors agree not to share their password and other access information with others.'
  },
  {
    title: 'Privacy',
    body: 'This section alerts visitors to your privacy policy, if you have one, and explains the general situations that you may be required to disclose visitor information to others; for instance, to respond to legal claims or requests for customer service.\n'
  },
  {
    title: 'Your Rights and Ownership',
    body: 'With the exception of any User Content that may be submitted by visitors, you own all intellectual property rights to the content on the website. Visitors agree not to sell, copy, or otherwise misappropriate your intellectual property and to keep all copyrights, trademarks, and other intellectual property notices on all copies of your content.'
  },
  {
    title: 'Copyright Policy',
    body: `This section outlines the process for reporting any allegations of copyright infringement on your website according to the proper procedure outlined under 17 U.S.C. 512(c) and then designates your copyright agent responsible for receiving and investigating such notices. This information is necessary to comply with the safe harbor provisions of the Digital Millennium Copyright Act (DMCA).
These requirements include that you (a) provide notice to your users of your policies regarding copyright infringement and the consequences of repeated infringing activity, (b) follow the proper notice and takedown procedures, including removing, or disabling access to, the material, providing notice to the individual responsible for such material, providing such individual with an opportunity to provide proper "counter-notice," and complying with applicable procedures thereafter, and (c) designate an agent to receive notices of infringement from copyright owners.`
  },
  {
    title: 'Sales, Refund, and Cancellation Policies',
    body: `If you sell goods or services through your website, then you should outline your sales policy in this section, including any conditions of sale, return policies, guarantees, payment details, fees, and shipping terms. Doing so helps avoid potential disputes and lawsuits with aggrieved visitors.
Likewise, you should include any refund and cancellation policies you may have in order to put visitors on notice and avoid conflicts.`
  },
  {
    title: 'Disclaimers',
    body: `The disclaimers include many of your most important terms and conditions. Among these, visitors are told that the site is provided on an "as-is" basis, and potential express and implied warranties are disclaimed in order to prevent visitors from seeking to hold you liable for promises you do not intend to offer. This also includes disclaimers for any loss or damages that may occur to visitors' computers, internet connections, or User Content.
Visitors are told that the content on your website is for informational purposes only and does not offer any professional advice, including legal, financial, or medical advice.`
  },
  {
    title: 'Limitation of Liability',
    body: `This is another important section because it limits your potential liability for visitors' use of the site to a maximum of the greater of $100 or amounts paid by the visitors in the past six months.
Visitors also agree not to bring any claims personally against any of your subsidiaries, officers, employees, contractors, agents, affiliates, partners, suppliers, or licensors.`
  },
  {
    title: 'Indemnity and Release',
    body: `Here, visitors agree to indemnify you against (meaning pay for) any claims by third parties that result from visitors' use of the site.
Visitors agree not to pursue any claim or action they may have against you or your affiliates.`
  },
  {
    title: 'Termination and Modification',
    body: `You may terminate visitors' access to any part or service on the site at any time in your sole discretion, without notice.
You may also update these terms at any time. Visitors' continued use of the site constitutes acceptance of such updates. You will provide visitors with notice of such updates by any reasonable means, including by email or posting notice in a prominent place on the site.`
  },
  {
    title: 'Violation of These Terms',
    body: 'You have the right to take any and all appropriate actions you deem necessary should a visitor breach these terms. This may include suspending, blocking, or terminating their access.'
  },
  {
    title: 'Dispute Resolution',
    body: 'If you choose to include this option, all disputes relating to the site, services, or these terms are required to be resolved through mandatory binding arbitration administered by the American Arbitration Association.'
  },
  {
    title: 'Feedback and Complaints',
    body: 'Visitors assign you the right to use and fully exploit all feedback or complaints they submit in any manner you wish, commercial or otherwise.'
  },
  {
    title: 'Contact Information',
    body: 'The last section includes the website operator\'s contact information, which is an important requirement that helps visitors report any violations of your terms that they may witness and to contact you for help and other matters.'
  }

]
