import React, {useState} from 'react'
import {Card} from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import StyledButton from '../../components/StyledButton/StyledButton'
import AxiosServer from '../../config/server'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import StyledCard from '../../components/StyledCard/StyledCard'
import CardHeader from '@material-ui/core/CardHeader'
import StyledTextField from '../../components/StyledTextField/StyledTextField'


const ForgotPasswordView: React.FC = () => {
  const [emailState, setEmail] = useState<string>('')
  const [responseState, setResponseState] = useState<string>('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleSubmit = async () => {
    try {
      const { data } = await AxiosServer.post('/auth/forgot-password', { email: emailState })
      setResponseState(data.message)
    } catch (e) {
      setResponseState(e.response.data.message)
    }

  }
  return (
    <Grid container justify={'center'}>
      <Grid item xs={12} md={5} lg={4}>
        <StyledCard>
          <CardHeader title='Reset Password'/>
          <CardContent style={{textAlign: 'center'}}>
            <Box mb={2}>
              <StyledTextField
                type={'email'}
                onChange={handleInputChange}
                fullWidth
                label={'Email'}
                variant={'outlined'}
                value={emailState}
              />
            </Box>
            {responseState && <Box mb={2}>{ responseState }</Box>}
            <StyledButton variant='contained' onClick={handleSubmit}>Submit</StyledButton>
          </CardContent>
        </StyledCard>
      </Grid>
    </Grid>

  )
}

export default ForgotPasswordView
