// React
import React  from 'react'
// Router Packages
import { Route, useRouteMatch } from 'react-router-dom'
import ManageGiveawaysView from './ManageGiveaways/ManageGiveawaysView'
import ManageRedemptionsView from './ManageRedepmtionsView'
import ManageUsersView from './ManageUsers/ManageUsersView'
import EditGiveawayView from './ManageGiveaways/EditGiveawayView'
import CreateGiveawayView from './ManageGiveaways/CreateGiveawayView'
import ManageEntriesView from './ManageEntries/ManageEntriesView'



const AdminRootView: React.FC = () => {
  const match = useRouteMatch('/admin')
  if (match) {
    return (
      <React.Fragment>
        <Route path={`${match.url}/manage-giveaways`} component={ManageGiveawaysView} exact/>
        <Route path={`${match.url}/manage-giveaways/create`} component={CreateGiveawayView} exact strict/>
        <Route path={`${match.url}/manage-giveaways/g/:giveawayId`} component={EditGiveawayView}/>
        <Route path={`${match.url}/manage-redemptions`} component={ManageRedemptionsView}/>
        <Route path={`${match.url}/manage-users`} component={ManageUsersView}/>
        <Route path={`${match.url}/manage-users/u/:userId`} component={ManageUsersView}/>
        <Route path={`${match.url}/manage-entries`} component={ManageEntriesView}/>
      </React.Fragment>
    )
  }
  return (<div/>)

}

export default AdminRootView
