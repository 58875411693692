import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// Fonts
let MuiTheme = createMuiTheme({
  typography: {
    allVariants: {
      color: 'white'
    },
    fontFamily: [
      '"cubano"',
      '"Roboto"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontFamily: 'cubano',
      fontWeight: 900,
      textTransform: 'uppercase',

    },
    h2: {
      fontFamily: 'cubano',
      fontWeight: 900,
      textTransform: 'uppercase'
    },
    h3: {
      fontFamily: 'cubano',
      fontWeight: 900,
      textTransform: 'uppercase',
      marginBottom: 10,
    },
    h4: {
      fontFamily: 'cubano',
      fontWeight: 600
    },
    h5: {
      fontFamily: 'cubano',
      fontSize: 30
    },
    h6: {
      fontFamily: 'cubano'
    },
    body1: {
      fontFamily: 'cubano',
      color: 'white',
      fontSize: 25
    },
    body2: {
      fontFamily: 'Helvetica',
      color: 'white',
      fontSize: 25
    },
    caption: {
      fontSize: 20
    }
  },
  direction: 'rtl',
  overrides: {
    MuiPaper: {
      root: {
        blackgroundColor: 'black'
      }
    },
    MuiTable: {
      root: {
        backgroundColor: 'black'
      }
    },
    MuiTableHead: {
      root: {
        color: 'white'
      }
    },
    MuiTableBody: {
      root: {
        backgroundColor: 'black'
      }
    },
    MuiTableCell: {
      root: {
        color: 'white'
      },
      head: {
        color: 'white'
      },
      body: {
        color: 'white'
      }
    },
    MuiAppBar: {
      root: {
        backgroundColor: 'black'
      }
    },
    MuiCheckbox: {
      root: {
        color: '#B94040',
      }
    },
    MuiListItem: {
      root: {
        backgroundColor: 'black',
        '&:hover': {
          backgroundColor: 'gray'
        }
      },
    }
  },
  palette: {
    primary: {
      main: '#B94040'
    },
    secondary: {
      main: '#000080'
    }
  }
});

// Responsive Font Sizes Helper
MuiTheme = responsiveFontSizes(MuiTheme);


export default MuiTheme;
