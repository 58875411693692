import React from 'react'
import { Theme, Button } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Giveaway, GiveawayItem } from '../../types/Giveaway'
import GiveawayItemTile from './GiveawayItemTile'
import CountdownComponent from './CountdownComponent'
import Fade from '@material-ui/core/Fade'
import Hidden from '@material-ui/core/Hidden'
import {Link} from 'react-router-dom'
import StyledButton from '../../components/StyledButton/StyledButton'
import TitleComponent from '../../components/TextComponents/TitleComponent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FacebookShareButton from '../../components/ShareButtons/FacebookShareButton'
import TwitterShareButton from '../../components/ShareButtons/TwitterShareButton'
import SubheadingComponent from '../../components/TextComponents/SubheadingComponent'
import Tooltip from '@material-ui/core/Tooltip'
import StyledCard from '../../components/StyledCard/StyledCard'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'white',
      margin: `0 ${theme.spacing(2)}px`,
      [theme.breakpoints.down('md')]: {
        margin: 0
      }
    },
    titleContainer: {
      letterSpacing: 1,
      [theme.breakpoints.down('md')] : {
        textAlign: 'center',
        position: 'relative',
        margin: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        padding: `${theme.spacing(0)}px ${theme.spacing(4)}px`,
        width: 'auto',
      }
    },
    descriptionContainer: {
      letterSpacing: 0,
      marginTop: theme.spacing(2),
    },
    itemsContainer: {

    },
    cardMedia: {
      height: 300
    },

  })
)

interface Props {
  giveaway: Giveaway;
}
const formattedGiveawayType = (type: string) => {
  switch (type) {
  case 'beginner':
    return 'Beginner'
  case 'expert':
    return 'Expert'
  case 'modern':
    return 'Modern Classic'
  default:
    return 'Beginner'
  }
}

const GiveawayComponent: React.FC<Props> = ({ giveaway }) => {
  const classes = useStyles()
  const formattedType = formattedGiveawayType(giveaway.type)

  return (
    <div className={classes.root}>
      <Grid container justify={'center'}>
        <Box my={2} textAlign='center'>
          <Typography variant='h2'>{giveaway.title}</Typography>
          <Box my={2}>
            <CountdownComponent endDate={giveaway.endDate}/>
          </Box>
          <Box my={2}>
            <Typography variant='body2'>{giveaway.description}</Typography>
          </Box>
          <Box mt={4}>
            <a href={giveaway.officialRulesUrl} target='_blank'><StyledButton>Official Rules</StyledButton></a>
          </Box>
        </Box>
        <Grid container spacing={4} justify={'center'}>
          {giveaway.items.map((giveawayItem: GiveawayItem) => {
            return (
              <Grid item xs={12} md={6} lg={4}>
                <Fade in={true} style={{ transitionDelay: '100ms' }} key={giveawayItem.id}>
                  <StyledCard>
                    <CardHeader title={giveawayItem.name}/>
                    <CardMedia
                      className={classes.cardMedia}
                      image={giveawayItem.photoUrl}
                    />
                    <CardContent>
                      <Typography variant='body1' style={{ fontFamily: 'Helvetica'}}>{ giveawayItem.description }</Typography>
                      <Typography variant='body1' style={{ fontFamily: 'Helvetica'}}>Est. Value: ${ giveawayItem.value }</Typography>
                    </CardContent>
                  </StyledCard>
                </Fade>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </div>
  )
}

export default GiveawayComponent
