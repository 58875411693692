import React, { createContext, useReducer } from 'react'
import AxiosServer from '../../config/server'
import {SubscriptionTier} from '../../types/Subscription'
import { CheckoutState, InitialCheckoutState, Reducer } from './reducer'
import {useAuthStore} from '../useAuth'


interface ContextProps extends CheckoutState {
  addPlan: (tier: SubscriptionTier) => void;
  removePlan: (tier: SubscriptionTier) => void;
  setValue: (key: string, value: string | boolean) => void;
  submitCheckoutForm: (body: SubmitBody) => void;
}

interface ProviderProps {
  children: React.ReactNode;
}

interface SubmitBody extends CheckoutState{
  paymentMethod: any;
}

export const CheckoutStore = createContext({} as ContextProps)

export const CheckoutStoreProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(Reducer, InitialCheckoutState)
  const { setUser } = useAuthStore()
  function addPlan(tier: SubscriptionTier) {
    dispatch({ type: 'addPlan', payload: tier})
  }

  function removePlan(tier: SubscriptionTier) {
    dispatch({ type: 'removePlan', payload: tier})
  }

  function setValue(id: string, value: string) {
    dispatch({ type: 'setValue', payload: { id, value } })
  }

  async function submitCheckoutForm(submitBody: SubmitBody) {
    const { data } = await AxiosServer.post('/payments/create-subscription', {
      ...submitBody,
      plans: submitBody.plans.map((x) => x.id)
    })
    if (data.user) {
      await setUser(data.user)
    }
  }

  const providerValue = {
    ...state,
    addPlan,
    removePlan,
    setValue,
    submitCheckoutForm
  }

  return (
    <CheckoutStore.Provider value={providerValue}>
      { children }
    </CheckoutStore.Provider>
  )
}
