import {createStyles, Theme, withStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const StyledTextField = withStyles((theme: Theme) => createStyles({
  root: {
    borderRadius: 5,

    color: 'white',
    '& .MuiOutlinedInput-root, & .MuiOutlinedInput-multiline': {
      '& fieldset': {
        borderColor: 'white',
        color: 'white',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '& textarea': {
        color: 'white',
        fontFamily: 'Helvetica',
        fontSize: 18,
        padding: 0,
      },
      '& input': {
        color: 'white',
        fontFamily: 'Helvetica',
        fontSize: 18,
        height: 61,
        padding: 0,
        paddingLeft: 15,
      }
    },
    '& .MuiFormLabel-root': {
      color: 'white',
      fontFamily: 'Helvetica'
    },
    '& .MuiFormHelperText-root': {
      color: 'white'
    }
  },
}))(TextField)

export default StyledTextField
