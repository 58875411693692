import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'
import StyledSelect from '../../components/StyledSelect/StyledSelect'
import {NativeSelect} from '@material-ui/core'
import StyledTextField from '../../components/StyledTextField/StyledTextField'
import StyledInputLabel from '../../components/StyledInputLabel/StyledInputLabel'

interface Props {
  handleSelect: (name: string, value: string | unknown) => void;
  value: string;
}
const states =  ['', 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA',
  'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
  'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS',
  'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI',
  'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH',
  'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH',
  'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA',
  'WA', 'WV', 'WI', 'WY']


const StateDropdown: React.FC<Props> = (props) => {
  // input label styles and stuff
  const inputLabel = React.useRef<HTMLLabelElement>(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth)
  }, [])

  const handleChange = (name: string) => (
    event: React.ChangeEvent<{ value: string | unknown }>,
  ) => {
    props.handleSelect(name, event.target.value)
  }
  return (
    <React.Fragment>
      <StyledInputLabel ref={inputLabel}>State</StyledInputLabel>
      <NativeSelect
        fullWidth
        variant='outlined'
        value={props.value}
        onChange={handleChange('state')}
        inputProps={{
          name: 'state',
          id: 'state-select'
        }}
        input={<StyledSelect/>}
      >
        {
          states.map((country, key) => {
            return ( <option value={country} key={key}>{country}</option> )
          })
        }
      </NativeSelect>
      <FormHelperText>Giveaway only available to residents of continental United States.</FormHelperText>
    </React.Fragment>
  )
}

export default StateDropdown
