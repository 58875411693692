import React from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Theme} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      maxHeight: 500,
      maxWidth: 1920,
      margin: 'auto',
      padding: theme.spacing(10),
      '& a': {
        color: 'white'
      },
      overflow: 'hidden'
    },
    footerList: {
      display: 'flex',
      justifyContent: 'flex-end',
      textAlign: 'center',
      listStyle: 'none',
      padding: 0,
      margin: 0,
      '& li': {
        paddingRight: theme.spacing(4),
        marginBottom: theme.spacing(4),
        margin: 0
      }
    },
  })
)

const Footer: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container justify={'center'} style={{ maxWidth: 1920, margin: 'auto' }}>
        <Grid item sm={12} md={6}>
          <img src={'./assets/FooterLogo.png'}/>
        </Grid>
        <Grid item sm={12} md={6}>
          <ul className={classes.footerList}>
            <li><Typography variant='h6'><Link to={'/terms-and-conditions'}>Terms and Conditions</Link></Typography></li>
            <li><Typography variant='h6'><Link to={'/privacy-policy'}>Privacy Policy</Link></Typography></li>
            <li><Typography variant='h6'><Link to={'/contact'}>Contact</Link></Typography></li>
          </ul>
        </Grid>
      </Grid>
      <Box textAlign={'center'} mt={4}><Typography variant='body1' style={{ fontSize: 20 }}>Blockhead Moto &copy; { new Date().getFullYear() } | No Purchase Necessary</Typography></Box>
    </div>
  )
}

export default Footer
