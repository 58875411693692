import React, { useEffect, useState } from 'react'
import {CardElement, injectStripe } from 'react-stripe-elements'
import StyledButton from '../StyledButton/StyledButton'
import {Box, Theme} from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Grid from '@material-ui/core/Grid'
import {Tiers} from '../../data/Tiers'
import { useHistory } from 'react-router-dom'
import FormControl from '@material-ui/core/FormControl'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {createStyles} from '@material-ui/styles'
import {useAuthStore} from '../../hooks/useAuth'
import {red} from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'
import StateDropdown from '../../views/Register/StateDropdown'
import FormHelperText from '@material-ui/core/FormHelperText'
import CountryDropdown from '../../views/Register/CountryDropdown'
import PlanSelector from './PlanSelector'
import {useCheckoutStore} from '../../hooks/useCheckout'
import StyledTextField from '../StyledTextField/StyledTextField'
import StyledCard from '../StyledCard/StyledCard'
import Typography from '@material-ui/core/Typography'
import * as queryString from 'querystring'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    formControl: {
      marginBottom: theme.spacing(2)
    },
    progress: {
      marginLeft: theme.spacing(2)
    }
  })
))

const CheckoutForm: React.FC<any> = ({ stripe }) => {
  const classes = useStyles()
  const history = useHistory()
  const { user } = useAuthStore()
  const { setValue, submitCheckoutForm, plans, fullName, street, city, state, country, zipCode, email, addPlan, agree } = useCheckoutStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [responseState, setResponseState] = useState<string>('')
  const [validated] = useState<boolean>(false)

  useEffect(() => {
    const query = queryString.parse(history.location.search)
    if (query.plans) {
      const bundlePlans = (query.plans as string).split(',')
      bundlePlans?.forEach((plan: string) => {
        const tierIndex = Tiers.findIndex(x => plan === x.id)
        if (tierIndex !== -1) {
          addPlan(Tiers[tierIndex])
        }
      })
    } else {
      const currentPlans = user.stripeProfile?.plans
      currentPlans?.forEach((plan: string) => {
        const tierIndex = Tiers.findIndex(x => plan === x.id)
        if (tierIndex !== -1) {
          addPlan(Tiers[tierIndex])
        }
      })
    }
  }, [])

  useEffect(() => {
    setValue('email', user.email)
  }, [user.email])

  const handleCheckBoxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, event.target.checked as boolean)
  }

  const handleInputChange = ({ target : { id, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(id, value)
  }

  const handleSelect = (name: string, value: string | unknown) => {
    if (value !== 'United States') {
      setValue('state', '')
    }
    setValue(name, value as string)
  }

  const validate = () => {
    const errors: string[] = []
    if (plans.length === 0) errors.push('Please select a plan')
    if (email.length === 0) errors.push('Email cannot be empty')
    if (fullName.length === 0) errors.push('Full Name cannot be empty')
    if (street.length === 0) errors.push('Street cannot be empty')
    if (city.length === 0) errors.push('City cannot be empty')
    if (state.length === 0) errors.push('State cannot be empty')
    if (country.length === 0) errors.push('Country cannot be empty')
    if (zipCode.length === 0) errors.push('ZipCode cannot be empty')
    if (!agree) errors.push('Please agree to the subscription conditions.')
    return errors
  }

  const submit = async () => {
    const errors = validate()
    if (errors.length > 0) {
      setResponseState(errors[0])
      return
    }
    if (!isLoading) {
      try {
        setIsLoading(true)
        const stripeResponse = await stripe.createPaymentMethod('card', {
          billing_details: { email }
        })
        if (stripeResponse.error) {
          const error = new Error(stripeResponse.error.message)
          throw error
        }
        const body = {
          fullName, street, city, state, country, zipCode, email,
          paymentMethod: stripeResponse.paymentMethod,
          plans,
          agree
        }
        await submitCheckoutForm(body)
        history.push('/giveaway?subscription=approved')
      } catch(e) {
        if (e.response) {
          setResponseState(e.response.data.message)
        } else if (e.message) {
          setResponseState(e.message)
        } else {
          setResponseState(e.response.data.message)
        }
        setIsLoading(false)
      }
    }
  }

  return (
    <div className={classes.root}>
      <Grid container justify={'center'} spacing={2}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant='h2'>Subscribe to Blockhead Moto</Typography>
        </Grid>
        <Grid item xs={12} md={5} lg={3}>
          <StyledCard style={{
            backgroundColor: 'rgba(255,255,255,0.1)'
          }}>
            <CardContent>
              <PlanSelector/>
              <Box py={1} fontWeight={500} fontStyle={'italic'}>
                Current Subscribers: If you change your plan it will be reflected during the next billing period
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={5} lg={3}>
          <div className="checkout">
            <StyledCard style={{
              backgroundColor: 'rgba(255,255,255,0.1)'
            }}>
              <CardContent>
                <FormControl className={classes.formControl} fullWidth>
                  <StyledTextField
                    id='fullName'
                    value={fullName}
                    onChange={handleInputChange}
                    label='Full Name'
                    variant='outlined'
                  />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <StyledTextField
                    id='street'
                    value={street}
                    onChange={handleInputChange}
                    label='Billing Address'
                    variant='outlined'
                  />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <StyledTextField
                    id='city'
                    value={city}
                    onChange={handleInputChange}
                    label='City'
                    variant='outlined'
                  />
                </FormControl>
                {
                  country === 'United States' && (
                    <FormControl error={!state && validated} variant='outlined' fullWidth className={classes.formControl}>
                      <StateDropdown handleSelect={handleSelect} value={state}/>
                      <FormHelperText>{ !state && validated ? 'Cannot be empty' : '' }</FormHelperText>
                    </FormControl>
                  )
                }
                <FormControl error={country === '' && validated} variant='outlined' fullWidth className={classes.formControl}>
                  <CountryDropdown handleSelect={handleSelect} value={country} validated={true}/>
                  <FormHelperText>{ country === '' && validated ? 'Cannot be empty' : '' }</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <StyledTextField
                    id='zipCode'
                    value={zipCode}
                    onChange={handleInputChange}
                    label='Zip Code'
                    variant='outlined'
                  />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <StyledTextField
                    id='email'
                    value={email}
                    onChange={handleInputChange}
                    label='Email'
                    variant='outlined'
                    disabled={true}
                    helperText={'You can update your email from the account settings page'}
                  />
                </FormControl>

                <Box px={1} py={2}>
                  <CardElement style={{
                    base: {
                      iconColor: '#c4f0ff',
                      color: '#fff',
                      fontWeight: 500,
                      fontFamily: 'Helvetica',
                      fontSize: '20px',
                      fontSmoothing: 'antialiased',
                      ':-webkit-autofill': {
                        color: 'white',
                      },
                      '::placeholder': {
                        color: '#EFEFEF',
                      },
                    },
                    invalid: {
                      iconColor: 'red',
                      color: 'red',
                    },
                  }}/>
                </Box>
                <FormControl style={{ textAlign: 'left', padding: '25px 0'}}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={agree} color='primary' onChange={handleCheckBoxChange('agree')} />
                    }
                    label={<Box fontFamily='Teko'>I understand that monthly subscriptions do not expire at the end of a sweepstakes period.</Box>}
                  />
                </FormControl>
                <Box style={{color: red[500]}} py={1}>
                  {responseState}
                </Box>
                <Box py={1} fontWeight={500} fontStyle={'italic'}>
                  Current Subscribers: If you change your plan, you will not be billed until the next billing cycle.
                </Box>
              </CardContent>
              <CardActions style={{textAlign: 'center', justifyContent: 'center'}} >
                <Box p={2} display={'flex'} alignItems={'center'}>
                  <StyledButton variant='contained' onClick={submit}>Confirm Subscription</StyledButton>
                  {isLoading && <CircularProgress size={24} className={classes.progress} />}
                </Box>
              </CardActions>
              <CardContent>
                <Box fontWeight={800} textAlign='center'>No Refunds</Box>
                <Box my={2} textAlign='center'>
                  <Box>
                    Powered by
                  </Box>
                  <img src='/assets/stripe.png' style={{height: 50}} alt={'stripe'}/>
                  <img src='/assets/creditcards.png' style={{height: 50}} alt={'accepted-cards'}/>
                </Box>
              </CardContent>
            </StyledCard>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}


export default injectStripe(CheckoutForm)
