import React from 'react'
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const BorderLinearProgress = withStyles({
  root: {
    height: 15,
    borderRadius: 20
  },
  bar: {
    borderRadius: 20
  },
})(LinearProgress)

interface Props {
  value: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    margin: {

    },
  }),
)

const ProgressBar: React.FC<Props> = ({ value }) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <BorderLinearProgress
        className={classes.margin}
        variant='determinate'
        color='primary'
        value={value}
      />
    </React.Fragment>
  )
}

export default ProgressBar
