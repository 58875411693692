import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import StyledButton from '../StyledButton/StyledButton'
import StyledDialog from '../StyledDialog/StyledDialog'
import {Typography} from '@material-ui/core'
import Box from '@material-ui/core/Box'

interface IProps {
  visible: boolean;
  onClose(): void;
}


const ConfirmSubscriptionModal: React.FC<IProps> = ({ visible, onClose }) => {

  return (
    <StyledDialog open={visible}>
      <DialogTitle>Thanks for Subscribing!</DialogTitle>
      <DialogContent>
        <Typography variant='body2'>
          We hope you enjoy your perks and keeping track of your entries to win. Please note it will take upto 24 hours for your account to update with your entries.
        </Typography>
        <Box mt={4}>
          <StyledButton variant={'contained'} onClick={() => { onClose() }}>Okay</StyledButton>
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default ConfirmSubscriptionModal
