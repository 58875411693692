const constants: any = {
  maxEntries: {
    beginner: 800,
    expert: 1000,
    modern: 800
  },
  colors: {
    primary: '#f4511e',
    secondary: '#000080'
  }
};

export default constants;
