import React, {useRef} from 'react'
import {Theme} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import {Link} from 'react-router-dom'
import StyledButton from '../../components/StyledButton/StyledButton'
import {Tiers} from '../../data/Tiers'
import {Testimonials} from '../../data/Testimonials'
import PricingComponent from '../../components/PricingComponent/PricingComponent'
import {SubscriptionTier} from '../../types/Subscription'
import Typography from '@material-ui/core/Typography'
import {useAuthStore} from '../../hooks/useAuth'
import TestimonialCard from '../../components/TestimonialCard/TestimonialCard'
import {Testmonial} from '../../types/Testimonial'
import IconButton from '@material-ui/core/IconButton'
import {easeInOutQuad} from '../../utils/overrides'
import {
  ArrowLeftRounded,
  ArrowRightRounded
} from '@material-ui/icons'



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'black',
      maxWidth: 1920,
      marginRight: 'auto',
      marginLeft: 'auto'
    },
    section: {
      padding: `${theme.spacing(4)}px 0`
    },
    sectionTitle: {
      fontWeight: 800,
      fontStyle: 'italic',
      fontSize: 38,
      textAlign: 'center',
      padding: theme.spacing(4)
    },
    jumbotron: {
      backgroundColor: 'black',
      color: 'white',
      textAlign: 'center',
      height: 500,
      [theme.breakpoints.up('md')]: {
        height: '70vh',
      },
    },
    jumbotronVideoContainer: {
      [theme.breakpoints.up('md')]: {
        height: '80vh',
      },
      height: 500,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      overflow: 'hidden'
    },
    jumbotronVideo: {
      [theme.breakpoints.down('sm')]: {
        transform: 'translate(-20%, -20%)'
      },
      [theme.breakpoints.up('md')]: {
        transform: 'translate(0)'
      },
    },
    jumbotronText: {

    },

    jumbotronTitle: {
      fontFamily: 'Cubano',
      fontSize: 40,
      paddingTop: 100,
      [theme.breakpoints.up('md')]: {
        fontSize: 100
      },
      zIndex: 1
    },

    socialModule: {
      padding: `${theme.spacing(10)}px 0`,
      backgroundColor: 'black',
      color: 'white',
      textAlign: 'center'
    },

    socialList: {
      listStyle: 'none',
      display: 'flex',
      '& li': {
        marginRight: 25
      }
    },

    tiersModule: {
      backgroundColor: '#B94040',
      padding: '5rem',
      [theme.breakpoints.down('md')]: {
        padding: `0 ${theme.spacing(0)}px`,
      },
    },

    testimonialModule: {
      padding: '0 0rem',
      [theme.breakpoints.down('md')]: {
        padding: '0',
      },
      '& .icon-left': {
        padding: 0,
        left: 30,
        width: 0
      },
      '& .icon-right': {
        padding: 0,
        right: 30,
        width: 0
      },
    },
    aboutModule: {
      backgroundImage: 'url("assets/about-banner.jpg")',
      backgroundSize: 'cover',
      backgroundPosition: 'bottom',
      position: 'relative',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        backgroundImage: 'url("assets/about-banner-cutoff.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
      },
    },
    cover: {
      height: '100%',
      width: '100%',
      top: 0,
      backgroundColor: 'rgba(0,0,0,.55)',
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(10)}px ${theme.spacing(4)}px`,
      },
    },
    testimonialWrapper: {
      display: 'flex',
      justifyContent: 'center',
      margin: '0',
      padding: 0,
    },
    testimonialContainer: {
      display: 'flex',
      overflowX: 'scroll',
      flexWrap: 'nowrap',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      '&>:nth-child(1)': {
        marginLeft: 0
      }
    },

  })
)

const HomeView: React.FC = () => {
  const classes = useStyles()
  const { isUserLoggedIn } = useAuthStore()
  const testimonialRef = useRef<HTMLDivElement>(null)

  const handleScroll = (direction) => {
    const start = testimonialRef.current.scrollLeft
    const child = testimonialRef.current.children[1] as HTMLDivElement
    console.log(child.offsetWidth)
    const change = direction === 1 ? child.offsetWidth + 32 : -1 * child.offsetWidth - 32

    let currentTime = 0
    const increment = 25
    const animationDuration = 300
    const animateScroll = () => {
      currentTime += increment
      const val = easeInOutQuad(currentTime, start, change, animationDuration)
      testimonialRef.current.scrollLeft = val
      if(currentTime < animationDuration) {
        setTimeout(animateScroll, increment)
      }
    }
    animateScroll()
  }


  return (
    <div className={classes.root}>
      <Box className={classes.jumbotron}>
        <div className={classes.jumbotronVideoContainer}>
          <video className={classes.jumbotronVideo} autoPlay playsInline muted loop>
            <source src='assets/videos/BHM_GAW_WEB.webm' type="video/webm"/>
            <source src='assets/videos/BHM_GAW_WEB.mp4' type="video/mp4"/>
          </video>
        </div>
        <div className={classes.jumbotronText}>
          <Box className={classes.jumbotronTitle} display={'inline-block'} position={'relative'} zIndex={1000}>
            Win custom <br/>Motorcycles!
          </Box>
          <Box>
            <Box display={'inline-block'} mr={2}>
              {
                isUserLoggedIn ?
                  null : <Link to={'/register'}><StyledButton style={{backgroundColor: 'black'}}>Become a Member</StyledButton></Link>
              }
            </Box>
            <Box display={'inline-block'}>
              {
                isUserLoggedIn ?
                  <Link to={'/giveaway'}><StyledButton style={{backgroundColor: 'black'}}>Continue</StyledButton></Link> :
                  <Link to={'/login'}><StyledButton style={{backgroundColor: 'black'}}>Login</StyledButton></Link>
              }
            </Box>
          </Box>
        </div>
      </Box>
      <Box className={classes.testimonialModule}>
        <Box textAlign={'center'}>
          <Typography variant={'h2'}>Testimonials</Typography>
        </Box>
        <Box className={classes.testimonialWrapper}>
          <IconButton className={'icon-left'} onClick={() => handleScroll(-1)}><ArrowLeftRounded color={'primary'} style={{ fontSize: 100}}/></IconButton>
          <div ref={testimonialRef} className={classes.testimonialContainer}>
            <div style={{ minWidth: 32, height: 100}}>&nbsp;</div>
            {
              Testimonials.map((testimonial: Testmonial) =>
                <TestimonialCard testimonial={testimonial}/>
              )
            }
            <div style={{ minWidth: 32, height: 100}}>&nbsp;</div>
          </div>
          <IconButton className={'icon-right'} onClick={() => handleScroll(1)}><ArrowRightRounded color={'primary'} style={{ fontSize:  100}}/></IconButton>
        </Box>
      </Box>
      <Box className={classes.tiersModule}>
        <Box textAlign={'center'} py={6} px={4}>
          <Typography variant={'h2'}>Subscription</Typography>
          <Typography variant={'body1'}>Choose from one of our premium membership options</Typography>
        </Box>
        <Grid container justify={'center'} style={{ maxWidth: 1440, margin: 'auto' }}>
          {
            Tiers.map((tier: SubscriptionTier) =>
              <Grid item xs={12} md={4} lg={5} key={tier.name}>
                <PricingComponent tier={tier}/>
              </Grid>
            )
          }
        </Grid>
      </Box>
      <Box className={classes.aboutModule}>
        {/*<img className={classes.aboutImage} src='./assets/about-banner.jpg'/>*/}
        <div className={classes.cover}>
          <Box mb={4}>
            <Typography variant='h2'>About Blockhead</Typography>
            <br/>
            <Typography variant='body1' style={{ fontFamily: 'Helvetica'}}>The foundation we've built ourselves on is simple: </Typography>
            <Typography variant='body2'><span style={{fontWeight: 'bolder', fontSize: '18pt'}}>WE LOVE MOTORCYCLES!</span></Typography>
          </Box>
          <Box>
            <Link to={'/about'}><StyledButton style={{backgroundColor: 'black'}}>Learn More</StyledButton></Link>
          </Box>
        </div>
      </Box>
      <Box className={classes.socialModule}>
        <Grid container justify={'center'}>
          <Typography variant='h2'>Follow us on Social Media</Typography>
          <ul className={classes.socialList}>
            <li><a href='https://www.facebook.com/BlockheadMoto' target='_blank'><img src={'./assets/social/white-fb.png'}/></a></li>
            <li><a href='https://www.instagram.com/blockheadmoto/' target='_blank'><img src={'./assets/social/white-ig.png'}/></a></li>
            <li><a href='https://twitter.com/blockheadmoto' target='_blank'><img src={'./assets/social/white-tw.png'}/></a></li>
            <li><a href='https://www.youtube.com/channel/UCm5PR071dIb_Vj4ujmGA3ow' target='_blank'><img src={'./assets/social/white-yt.png'}/></a></li>
          </ul>
        </Grid>
      </Box>
    </div>
  )
}

export default HomeView
