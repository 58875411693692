import React, { Dispatch, SetStateAction } from 'react'
import { Tiers } from '../../data/Tiers'
import { SubscriptionTier } from '../../types/Subscription'
import {useCheckoutStore} from '../../hooks/useCheckout'
import PlanItem from '../PlanItem/PlanItem'
import Box from '@material-ui/core/Box'
import PlanTotal from '../PlanTotal/PlanTotal'
import Divider from '@material-ui/core/Divider'
import {useAuthStore} from '../../hooks/useAuth'
import StyledSelect from '../StyledSelect/StyledSelect'
import {NativeSelect} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import StyledButton from '../StyledButton/StyledButton'

interface Props {
  selectedPlans: SubscriptionTier[];
  setSelectedPlans: Dispatch<SetStateAction<SubscriptionTier[]>>;
}

const PlanSelector: React.FC = () => {
  const { user } = useAuthStore()
  const { addPlan, removePlan, plans } = useCheckoutStore()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedTier = Tiers.find(x => x.id === event.target.value) as SubscriptionTier
    addPlan(selectedTier)
    console.log(plans)
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant={'h4'}>
        Select Plan
      </Typography>
      <NativeSelect
        fullWidth
        variant='outlined'
        value={''}
        onChange={handleChange}
        inputProps={{
          name: 'subscriptionPlan',
          id: 'subscriptionPlan'
        }}
        input={<StyledSelect/>}
      >
        <option value={''}>Select Plan</option>
        {
          Tiers.map(tier => <option value={tier.id} key={tier.id}>{`${tier.name}`}</option> )
        }
      </NativeSelect>
      <Divider/>
      <Box marginTop={2}>
        <PlanTotal selectedPlans={plans}/>
      </Box>
    </div>
  )
}

export default PlanSelector
