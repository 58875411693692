import React from 'react'

export type FAQ = {
  question: string;
  answer: JSX.Element;
};

export const FrequentlyAskedQuestions: FAQ[] = [
  {
    question: 'I know I’m not eligible for the giveaway, but can I still become a paid subscriber on the website?',
    answer: (<div>Of course! Every subscriber supports our content creation and lets us keep putting the awesome content that you love! And you're still eligible for the other perks.
    </div>)
  },
  {
    question: 'Can I enter the giveaway if I live outside of the United States, or in Alaska or Hawaii?',
    answer: (<div>Our giveaway is only open to legal US Residents in the 48 contiguous United States, excluding Alaska and Hawaii. Proof of residency will be verified if you are a potential winner.</div>)
  },
  {
    question: 'Do I need to be a paid subscriber to enter the giveaway?',
    answer: (<div>No purchase is required or necessary to enter our giveaway! Check out our official rules for the free method of entry.</div>)
  },
  {
    question: 'Are my entries permanent?',
    answer: (<div>No, they only count towards the most current giveaway season and will reset at the end once the winners have been drawn.</div>)
  },
  {
    question: 'I noticed my entries went down after a giveaway season ended. Do they always reset?',
    answer: (<div>Yes. Your entry counter will reset after each giveaway season.</div>)
  },

]
