import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import { History } from 'history'
import CircularProgress from '@material-ui/core/CircularProgress'
import server from '../../../config/server'
import { KeyboardDatePicker } from '@material-ui/pickers'
import FileUploader from '../../../components/FileUploader/FileUploader'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import StyledTextField from '../../../components/StyledTextField/StyledTextField'
import Typography from '@material-ui/core/Typography'
import StyledButton from '../../../components/StyledButton/StyledButton'
import StyledSelect from '../../../components/StyledSelect/StyledSelect'
import NativeSelect from '@material-ui/core/NativeSelect'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    toolbar: {
      borderBottom: '1px solid rgba(0,0,0,.15)'
    },
    flex: {
      flexGrow: 1
    },
    tableContainer: {
      marginTop: theme.spacing(4)
    },
    form: {
      marginTop: theme.spacing(4)
    },
    textField: {
      marginBottom: theme.spacing(2)
    },
    progress: {
      marginLeft: theme.spacing(2)
    },
    actionContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(2)
    },
    datePickerFields: {
      marginRight: theme.spacing(2),
      '& label': {
        fontFamily: 'Cubano'
      },
      '& input': {
        color: 'white'
      },
      '& fieldset': {
        borderColor: 'white',
        color: 'white',
      },
    },
    responseContainer: {
      marginTop: theme.spacing(2)
    },
    formControl: {
      marginBottom: theme.spacing(2)
    }
  })
)

interface IProps {
  history: History;
}

interface IState {
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  officialRulesUrl: string;
  type: string;
}

const CreateGiveawayView: React.FC<IProps> = (props) => {

  const classes = useStyles()
  const [formState, setFormState] = useState<IState>({
    title: '',
    description: '',
    startDate: new Date().toString(),
    endDate: new Date().toString(),
    officialRulesUrl: '',
    type: 'primary'
  })

  const [responseState, setResponseState] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [event.target.name]: event.target.value })
  }

  const handleTypeChange = (event: React.ChangeEvent<{ value: string | unknown }>) => {
    const value = event.target.value as string
    setFormState({...formState, type: value})
  }

  const handleDateChange = (field: string) => {
    return (date: Date | null) => {
      if (date)
        date.setHours(0,0,0,0)
      setFormState({ ...formState, [field]: date })
    }
  }

  const handleOfficialRulesUpload = (url: string) => {
    setFormState({ ...formState, 'officialRulesUrl': url })
  }

  const handleSubmit = async () => {
    if (new Date(formState.startDate) > new Date(formState.endDate)) {
      setResponseState('The start date must be before the end date')
    } else {
      try {
        setIsLoading(true)
        await server.post('/admin/giveaways', formState)
        setIsLoading(false)
        // Success
        props.history.push('/admin/manage-giveaways')
      } catch (e) {
        setIsLoading(false)
        setResponseState(e.response.data.message)
      }
    }
  }

  return (
    <div className={classes.root}>
      <Grid container justify='center'>
        <Grid item xs={12} md={8}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl className={classes.formControl} fullWidth>
              <NativeSelect
                variant='outlined'
                value={formState.type}
                onChange={handleTypeChange}
                inputProps={{
                  name: 'type',
                  id: 'type'
                }}
                input={<StyledSelect/>}
              >
                <option value={'primary'}>Primary Giveaway</option>
              </NativeSelect>
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <StyledTextField
                name='title'
                label='Title'
                onChange={handleChange}
                value={formState.title}
                variant='outlined'
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <StyledTextField
                name='description'
                label='Description'
                multiline
                rows={4}
                fullWidth
                onChange={handleChange}
                value={formState.description}
                variant='outlined'
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                id="date-picker-inline"
                label="Start Date"
                inputVariant="outlined"
                autoOk={true}
                value={formState.startDate}
                onChange={handleDateChange('startDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={classes.datePickerFields}
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                id="date-picker-inline"
                label="End Date"
                inputVariant="outlined"
                autoOk={true}
                value={formState.endDate}
                onChange={handleDateChange('endDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoFocus={true}
                className={classes.datePickerFields}
              />
            </FormControl>
            <Typography variant={'h4'}>
              Upload Official Rules
            </Typography>
            <Box>
              <FileUploader onUploadComplete={handleOfficialRulesUpload} />
              <Typography display='inline' variant='body2'>{ formState.officialRulesUrl && 'Rules Attached'}</Typography>
            </Box>
            <div className={classes.actionContainer}>
              <StyledButton onClick={handleSubmit}>Create Giveaway</StyledButton>
              {isLoading && <CircularProgress size={24} className={classes.progress} />}
            </div>
            <div className={classes.responseContainer}>{responseState}</div>
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

export default CreateGiveawayView
