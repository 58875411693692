import React from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import { SubscriptionTier } from '../../types/Subscription'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: 'white',
    },
    table: {
      '& .MuiTableCell-body': {
        color: 'white',
        backgroundColor: 'rgba(255,255,255,0.1)'
      }
    }
  })
)

interface PlanTotalProps {
  selectedPlans: SubscriptionTier[];
}


const PlanTotal: React.FC<PlanTotalProps> = ({ selectedPlans }) => {
  const classes = useStyles()

  const total = selectedPlans.reduce((a, b) => a + b.price, 0)
  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableBody>
          { selectedPlans.map((tier: SubscriptionTier) =>
            <TableRow>
              <TableCell>{tier.name}</TableCell>
              <TableCell align={'right'}>${(tier.price/100).toFixed(2)}/mo</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell/>
            <TableCell/>
          </TableRow>
          <TableRow>
            <TableCell>
              <Box fontWeight={600}>Total</Box>
            </TableCell>
            <TableCell align={'right'}>${(total/100).toFixed(2)}/mo</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

export default PlanTotal
