// React
import React from 'react'
import { AuthStoreProvider } from './hooks/useAuth/context'
import { EntryStoreProvider } from './hooks/useEntries/context'
// Router Packages
import {Route, Router} from 'react-router-dom'
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'

// Stylesheet
import './App.css'

// MUI Integration
import { ThemeProvider } from '@material-ui/styles'
import MuiTheme from './utils/theme'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CssBaseline from '@material-ui/core/CssBaseline'

// Components
import Navbar from './components/Nav/Navbar'
import PrivateRoute from './components/Routing/PrivateRoute'
import LoginView from './views/Login/LoginView'
import LogoutView from './views/LogoutView'
import HomeView from './views/Home/HomeView'
import GiveawayView from './views/Giveaway/GiveawayView'
import AdminRootView from './views/Admin/AdminRootView'
import RegisterView from './views/Register/RegisterView'
import AdminRoute from './components/Routing/AdminRoute'
import SubscriptionView from './views/Subscription/SubscriptionView'

import AddSubscriptionView from './views/Subscription/AddSubscriptionView'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import OfficialRulesView from './views/Legal/OfficialRulesView'

import TermsAndConditionsView from './views/Legal/TermsAndConditionsView'
import PrivacyPolicy from './views/Legal/PrivacyPolicy'
import AccountManageView from './views/Account/AccountManageView'
import ForgotPasswordView from './views/Forgot/ForgotPasswordView'
import ForgotPasswordResetView from './views/Forgot/ForgotPasswordResetView'
import ContactView from './views/Contact/ContactView'
import Footer from './components/Footer/Footer'
import ShopView from './views/Shop/ShopView'
import AboutView from './views/About/AboutView'
import DiscordAuthorizationView from './views/Discord/DiscordAuthorizationView'
import ServiceRequestView from './views/ServiceRequest/ServiceRequestView'
import NotificationBanner from './components/NotificationBanner/NotificationBanner'

// Analytics
ReactGA.initialize('UA-207894947-1')

// Views
const history = createBrowserHistory()
history.listen(location => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
})

const App: React.FC = () => {
  ReactGA.pageview(window.location.pathname)
  return (
    <React.Fragment>
      <CssBaseline/>
      <AuthStoreProvider>
        <EntryStoreProvider>
          <ThemeProvider theme={MuiTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Router history={history}>
                <ScrollToTop/>
                <Navbar/>
                <main>
                  <NotificationBanner/>
                  <Route path='/' exact component={HomeView} />
                  <Route path='/login' component={LoginView} />
                  <Route path='/logout' component={LogoutView} />
                  <Route path='/register' component={RegisterView} />
                  <Route path='/official-rules' component={OfficialRulesView}/>
                  <Route path='/terms-and-conditions' component={TermsAndConditionsView} />
                  <Route path='/privacy-policy' component={PrivacyPolicy} />
                  <Route path='/forgot' exact component={ForgotPasswordView} />
                  <Route path='/forgot/verify' exact component={ForgotPasswordResetView} />
                  <Route path='/contact' exact component={ContactView} />
                  <Route path='/giveaway' component={GiveawayView} />
                  <Route path='/shop' component={ShopView}/>
                  <Route path='/about' component={AboutView}/>
                  <Route path='/discord' component={DiscordAuthorizationView}/>
                  <Route path='/service-request' component={ServiceRequestView}/>
                  <PrivateRoute path='/account' component={AccountManageView} />
                  <PrivateRoute path='/subscribe' exact component={SubscriptionView} />
                  <PrivateRoute path='/subscribe/add' component={AddSubscriptionView} />
                  <AdminRoute path='/admin' component={AdminRootView} />
                </main>
                <Footer/>
              </Router>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </EntryStoreProvider>
      </AuthStoreProvider>
    </React.Fragment>
  )
}

export default App
