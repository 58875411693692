import {SubscriptionTier} from '../../types/Subscription'

export interface CheckoutState {
  plans: SubscriptionTier[];
  fullName: string;
  street: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  email: string;
  agree: boolean;
}

export interface ValueUpdate {
  id: string;
  value: string;
}

export type CheckoutActions = {
  type: 'setValue' | 'addPlan' | 'removePlan' | 'resetPlans';
  payload?: CheckoutState | SubscriptionTier | ValueUpdate ;
};

export const InitialCheckoutState: CheckoutState = {
  plans: [],
  fullName:  '',
  street: '',
  city: '',
  state: '',
  country: 'United States',
  zipCode: '',
  email: '',
  agree: false
}

export const Reducer = (state: CheckoutState, action: CheckoutActions): CheckoutState => {
  switch (action.type) {
  case 'addPlan': {
    const { plans } = state
    let newPlans: SubscriptionTier[] = []
    const tier = action.payload as SubscriptionTier
    const indexOfSameType = plans.findIndex(x => x.giveaway === tier.giveaway)
    if (indexOfSameType === -1) {
      newPlans = newPlans.concat(plans, tier)
    } else {
      newPlans = [...plans]
      newPlans.splice(indexOfSameType, 1, tier)
    }
    return { ...state, plans: newPlans }
  }
  case 'removePlan': {
    const { plans } = state
    const tier = action.payload as SubscriptionTier
    const newPlans = plans.filter(x => x.id !== tier.id)
    return { ...state, plans: newPlans }
  }
  case 'setValue': {
    const payload = action.payload as ValueUpdate
    return {
      ...state, [payload.id as string] : payload.value as string
    }
  }
  default:
    return state
  }
}
