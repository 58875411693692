import React from 'react'
import {Box, Typography} from '@material-ui/core'

export const ServicePolicy: React.FC = () => {
  return (
    <Box>
      <Box py={2} style={{
        border: '2px solid white',
        padding: '1rem'
      }}>
        <Typography variant={'h5'}>
          Deposit Policy
        </Typography>
        <Typography variant={'body2'} style={{
          fontSize: 15,
        }}>
          All scheduled services are subject to a minimum <Box component={'span'} fontWeight={'bolder'}>$150.00 non-refundable deposit</Box>, due at the time of scheduling.
          Services needing a parts order will require that that <Box component={'span'} fontWeight={'bolder'}>the parts be paid for upfront in full as the deposit.</Box>
          Services where the parts are being provided by the customer will require a <Box component={'span'} fontWeight={'bolder'}>$150 non-refundable deposit</Box> that will be applied toward the cost of labor.
        </Typography>
      </Box>
      <Box py={2} style={{
        border: '2px solid white',
        padding: '1rem'
      }}>
        <Typography variant={'h5'}>
          Cancellation/Reschedule Policy
        </Typography>
        <Typography variant={'body2'} style={{
          fontSize: 15
        }}>
          Cancellation or rescheduling requests must be made within a <Box component={'span'} fontWeight={'bolder'}>72 hour minimum</Box> period before any scheduled service.
          As stated in our Deposit Policy, all service and parts <Box component={'span'} fontWeight={'bolder'}>deposits are non-refundable</Box>, even if you cancel your requested service.
          We will do our best to accommodate a rescheduling request, but we cannot guarantee immediate availability.
        </Typography>
      </Box>
    </Box>
  )
}
