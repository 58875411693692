import {Testmonial} from '../types/Testimonial';

export const Testimonials: Testmonial[] = [
  {
    season: 5,
    bike: 'Indian Scout',
    name: 'Mychael',
    body: 'I went from watching the bike on the channel to riding it down my road! Couldn’t have asked for a better looking bike. Thanks Blockhead Moto!',
    location: 'Virgina Beach, VA',
    picture: '/assets/testimonials/S05-Mychael.jpg'
  },
  {
    season: 5,
    bike: 'Harley Iron',
    name: 'Lloyd',
    body: 'I never thought I would have ever won one of the giveaway bikes, especially the one that I would have picked in the first place, thanks Blockhead!',
    location: 'Grant, AL',
    picture: '/assets/testimonials/S05-Lloyd.jpg'
  },
  {
    season: 4,
    bike: 'Harley Dyna LRS',
    name: 'Luke Hungate',
    body: 'Man, the bike is fantastic! Looks even better in real life. You did an awesome job on this thing! Thanks again for making this possible.',
    location: 'Sacramento, CA',
    picture: '/assets/testimonials/S04-Dyna.jpg'
  },
  {
    season: 3,
    bike: 'Harley 48',
    name: 'Aaron Anderson',
    body: 'I am so stoked to see the bike in person! I\'m still in complete shock that I won. This kinda stuff don’t happen to me. I\'m really looking forward to this bike!',
    location: 'Shelbyville, IN',
    picture: '/assets/testimonials/S03-48.jpg'
  },
  {
    season: 2,
    bike: 'Harley Iron',
    name: 'Joseph Diaz',
    body: 'Yes! I am the winner and I am so freaking excited. Never win anything and to win this is a dream come true.',
    location: 'Gulfport, MS',
    picture: '/assets/testimonials/S02-Iron.jpg'
  },
  {
    season: 1,
    bike: 'Honda Grom',
    name: 'Joseph Lara',
    body: 'I’ve been watching your channel for close to a year! Ever since I watched your “How to Ride” videos, I’ve been dreaming about the day I’d get my first bike!',
    location: 'Little Rock, AR',
    picture: '/assets/testimonials/S01-Grom.jpg'
  }
];
