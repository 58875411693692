import React, { useState } from 'react'
import {Card, NativeSelect} from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import {useAuthStore} from '../../hooks/useAuth'
import Box from '@material-ui/core/Box'
import StyledButton from '../../components/StyledButton/StyledButton'
import Button from '@material-ui/core/Button'
import AxiosServer from '../../config/server'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import TitleComponent from '../../components/TextComponents/TitleComponent'
import StyledCard from '../../components/StyledCard/StyledCard'
import Typography from '@material-ui/core/Typography'
import PlanSelector from '../../components/Stripe/PlanSelector'
import Grid from '@material-ui/core/Grid'
import {SubscriptionTier} from '../../types/Subscription'
import {Tiers} from '../../data/Tiers'
import StyledSelect from '../../components/StyledSelect/StyledSelect'
import ChangePlanComponent from '../../components/ChangePlan/ChangePlanComponent'
import UpdatePaymentMethodComponent from '../Account/UpdatePaymentMethodView'
import {CheckoutStoreProvider} from '../../hooks/useCheckout/context'
import {Elements, StripeProvider} from 'react-stripe-elements'


const SubscriptionStatusCard: React.FC = () => {
  const { user, fetchUser } = useAuthStore()
  const stripeProfile = user.stripeProfile
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCancelSubscription = async () => {
    try {
      setIsLoading(true)
      await AxiosServer.post('/payments/cancel-subscription')
      await fetchUser()
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)
  }


  return (
    <StyledCard>
      <CardContent>
        <Typography variant='h6'>Current Subscription Tier</Typography>
        <Typography variant='h4'>
          Tier { stripeProfile?.highestTier || 'Free' }
        </Typography>
        {
          stripeProfile?.active && (
            <>
              <Box py={2}>
                <Typography variant='h6'>Subscription { (!stripeProfile?.isCanceled ? 'Renewal' : 'Expiration') } Date</Typography>
                <Typography variant='h4'>{ stripeProfile?.subscriptionPeriodEnd && new Date(stripeProfile?.subscriptionPeriodEnd).toDateString() }</Typography>

              </Box>
              <ChangePlanComponent/>
              <StripeProvider apiKey={'' + process.env.REACT_APP_STRIPE_KEY}>
                <Elements>
                  <UpdatePaymentMethodComponent/>
                </Elements>
              </StripeProvider>
            </>
          )
        }
        <Box my={4}>
          { (stripeProfile && !stripeProfile?.isCanceled) && (<StyledButton onClick={handleCancelSubscription}>Cancel Subscription</StyledButton> ) }
          { (!stripeProfile || stripeProfile?.isCanceled) && (<Link to={'/subscribe'}><StyledButton variant='contained'>Subscribe Today</StyledButton></Link> )  }
        </Box>
        <Box>
          { isLoading ? <CircularProgress size={24} style={{ padding: 8 }}/> : null }
        </Box>
      </CardContent>
    </StyledCard>
  )

}


export default SubscriptionStatusCard
