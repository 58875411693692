import React, { useState, forwardRef } from 'react'

import AxiosServer from '../../../config/server'
import { User } from '../../../types/User'
import MaterialTable from 'material-table'
import UserModal from './UserModal'
import StyledButton from '../../../components/StyledButton/StyledButton'
import {createStyles, makeStyles} from '@material-ui/styles'

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import SettingsIcon from '@material-ui/icons/Settings'


const tableIcons = {
  // @ts-ignore
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  // @ts-ignore
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  // @ts-ignore
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  // @ts-ignore
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiIconButton-label': {
        color: 'white'
      },
      '& .MuiSelect-root': {
        color: 'white'
      },
      '& .MuiTableSortLabel-root': {
        '&:hover': {
          color: 'white'
        }
      },
      '& .MuiTableSortLabel-active': {
        color: 'white'
      },
      '& .MTablePaginationInner-root-70': {
        flexDirection: 'row-reverse'
      }
    }
  })
)

const UserTable: React.FC = () => {
  const classes = useStyles()
  const [showingModal, setShowingModal] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<User>()

  const handleSelectUser = (user: User) => {
    return () => {
      showModal(user)
    }
  }

  const showModal = (user: User) => {
    setShowingModal(true)
    setSelectedUser(user)
  }

  const hideModal = () => {
    setShowingModal(false)
    setSelectedUser(undefined)
  }

  const columns = [
    { title: 'First Name', field: 'firstName'},
    { title: 'Last Name', field: 'lastName' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
    {
      title: 'Plan',
      field: 'subscriptionPlan',
      render: (user: User) => user.stripeProfile?.active ? `Tier ${user.stripeProfile.highestTier}` : 'Free'
    },
    {
      title: 'Join date',
      field: 'createdAt',
      render: (user: User) => new Date(user.createdAt).toDateString()
    },
    {
      title: 'More',
      render: (user: User) => <SettingsIcon onClick={handleSelectUser(user)}/>
    }
  ]

  return (
    <div className={classes.root}>
      <UserModal visible={showingModal} onClose={hideModal} user={selectedUser}/>
      <MaterialTable
        columns={columns}
        data={query =>
          new Promise(async (resolve, reject) => {
            console.log(query)
            try {
              let url
              // @ts-ignore
              if (!query.orderBy || query.orderDirection === '') {
                url = `/admin/users?pageSize=${query.pageSize}&page=${ query.page }&search=${query.search}`
              } else {
                url = `/admin/users?pageSize=${query.pageSize}&page=${ query.page }&search=${query.search}&orderDirection=${query.orderDirection || 'asc'}&orderBy=${query.orderBy.field || 'lastName'}`
              }
              const { data } = await AxiosServer.get(url)
              console.log(data.data)
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.totalCount
              })
            } catch (e) {
              reject(e)
            }
          })
        }
        title='Users'
        options={{
          pageSize: 50,
          searchFieldStyle: {
            color: 'white',
            fontFamily: 'Helvetica'
          },
          headerStyle: {
            backgroundColor: 'black',
            color: 'white',
            fontFamily: 'Cubano',
          },
        }}
        style={{
          backgroundColor: 'black',
          color: 'white',
        }}
      />
    </div>
  )
}

export default UserTable
