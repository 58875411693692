import React from 'react'
import { useHistory } from 'react-router-dom'
import {Theme} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/styles'
import {SubscriptionTier} from '../../types/Subscription'
import StyledButton from '../StyledButton/StyledButton'
import Box from '@material-ui/core/Box'

interface IProps {
 tier: SubscriptionTier;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '& a': {
      color: '#171717'
    },
    root: {
      margin: theme.spacing(5),
      // backgroundColor: '#171717',
      backgroundColor: 'black',
      color: 'white',
      boxShadow: 'none',
      borderRadius: 0,
      padding: `${theme.spacing(4)}px ${theme.spacing(2)}px  ${theme.spacing(6)}px  ${theme.spacing(2)}px`,
      textAlign: 'center'
    },
    features: {
      '& ul': {
        listStyle: 'none',
        fontSize: 18,
        padding: 0,
        '& li': {
          padding: `${theme.spacing(1)}px 0`
        }
      }
    },
    subscribeButton: {
      marginBottom: 0
    }
  })
)

const PricingComponent: React.FC<IProps> = ({ tier }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleOpenPaymentModal = () => (
    history.push({ pathname: '/subscribe/add', search: `plans=${tier.id}` })
  )

  return (
    <div className={classes.root}>
      <Box fontSize={30} fontFamily={'Cubano'} textAlign={'center'} py={2}>
        {tier.name}
      </Box>
      <Box style={{ height: 5, backgroundColor: '#797979', maxWidth: 200, marginRight: 'auto', marginLeft: 'auto'}} mb={2}></Box>
      <Box fontSize={45} fontFamily={'Cubano'}>
        ${(tier.price/100).toFixed(0)}/mo
      </Box>
      <div className={classes.features}>
        <ul>
          <li>{tier.entries} Giveaway Entries</li>
          <li>Early Access to Videos</li>
          <li>Big Discounts on the Blockhead Store</li>
          <li>Discord Server</li>
        </ul>
      </div>
      <StyledButton className={classes.subscribeButton} onClick={handleOpenPaymentModal}>
        Subscribe
      </StyledButton>
    </div>
  )
}

export default PricingComponent
