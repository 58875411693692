import React, { useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import AxiosServer from '../../../config/server'
import {User} from '../../../types/User'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import {Theme} from '@material-ui/core'
import StyledButton from '../../../components/StyledButton/StyledButton'
import StyledDialog from '../../../components/StyledDialog/StyledDialog'
import Typography from '@material-ui/core/Typography'
import StyledTextField from '../../../components/StyledTextField/StyledTextField'
import StyledSelect from '../../../components/StyledSelect/StyledSelect'
import NativeSelect from '@material-ui/core/NativeSelect'

interface IProps {
  visible: boolean;
  onClose: Function;
  user?: User;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    dialog: {
      minWidth: '40%'
    },
    formControl: {
      marginBottom: theme.spacing(2)
    }
  })
)

const UserModal: React.FC<IProps> = ({ visible, onClose, user }) => {
  const classes = useStyles()
  const [response, setResponse] = useState<string>('')
  const [entries, setEntries] = useState<number>(0)
  const [labelWidth] = useState(0)
  const [giveawayType, setGiveawayType] = useState('primary')
  console.log(user)
  const addPoints = async () => {
    if (user) {
      try {
        const { data } = await AxiosServer.post(`/admin/users/${user.id}/add-entries`, {
          entries,
          giveawayType
        })
        setResponse(data.message)
        setEntries(0)
        setTimeout(() => {
          onClose()
        }, 250)
      } catch (e) {
        setResponse(e.response.data.message)
      }
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEntries(parseInt(event.target.value))
  }

  const handleTypeChange = (event: React.ChangeEvent<{ value: string | unknown }>) => {
    const value = event.target.value as string
    setGiveawayType(value)
  }

  const handleResetStripeResetClick = async () => {
    if (window.confirm('Are you sure you wish to reset their stripe profile? This cannot be undone.')) {
      if (user.stripeProfile) {
        try {
          const { data } = await AxiosServer.get(`/admin/users/${user.id}/stripe-subscription-reset`)
          setResponse('Stripe subscription reset, user can now subscribe. Please delete subscription from Stripe web portal.')
        } catch (e) {
          setResponse(e.response.data.message)
        }
      } else {
        setResponse('User does not have a stripe profile')
      }
    }
  }

  return (
    <div className={classes.root}>
      <StyledDialog open={visible} className={classes.dialog} onClose={() => onClose()}>
        <DialogTitle>Add Entries</DialogTitle>
        <DialogContent>
          <form>
            <FormControl fullWidth>
              <NativeSelect
                value={giveawayType}
                onChange={handleTypeChange}
                inputProps={{
                  name: 'type',
                  id: 'type'
                }}
                input={<StyledSelect/>}
              >
                <option value={'primary'}>Primary</option>
              </NativeSelect>
            </FormControl>
            <FormControl fullWidth>
              <StyledTextField
                variant='outlined'
                type='number'
                onChange={handleInputChange}
                value={entries}
              />
            </FormControl>
          </form>
        </DialogContent>
        <Box m={2} textAlign={'center'}>
          <Typography variant='body2'>{response}</Typography>
        </Box>
        <DialogActions>
          <StyledButton onClick={addPoints}>Add Points</StyledButton>
          <StyledButton onClick={handleResetStripeResetClick}>Rest Stripe</StyledButton>
        </DialogActions>
      </StyledDialog>
    </div>
  )
}

export default UserModal
