import React, {useState} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import {Theme} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import FormControl from '@material-ui/core/FormControl'
import StyledButton from '../../components/StyledButton/StyledButton'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormErrorResponse from '../../components/FormError/FormErrorResponse'
import StyledTextField from '../../components/StyledTextField/StyledTextField'
import Typography from '@material-ui/core/Typography'
import StyledCard from '../../components/StyledCard/StyledCard'
import CardContent from '@material-ui/core/CardContent'
import server from '../../config/server'
import ReCAPTCHA from 'react-google-recaptcha'
import {ServicePolicy} from './ServicePolicy'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      maxWidth: 600,
      margin: 'auto'
    },
    logo: {
      height: 196,
      marginBottom: theme.spacing(4)
    },
    title: {
      marginBottom: theme.spacing(4)
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2),
    },
    progress: {
      margin: theme.spacing(2)
    },
    formControl: {
      marginBottom: theme.spacing(2)
    },
    form: {
      padding: theme.spacing(4)
    },
  })
)
const ServiceRequestView: React.FC = () => {
  const classes = useStyles()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [state, setState] = useState<State>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    motorcycleMake: '',
    motorcycleModel: '',
    motorcycleYear: '',
    motorcycleMileage: '',
    serviceRequestBody: '',
    serviceTime: '',
    dynoTuning: false,
    dynoExhaust: '',
    dynoIntake: '',
    priorWork: false,
    priorWorkBody: '',
    agree: false
  })

  const [validated, setValidated] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [captchaToken, setCaptchaToken] = useState<string>('')

  const handleSetToken = (token: string) => {
    setCaptchaToken(token)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleCheckBoxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: event.target.checked })
  }

  const validateForm = () => {
    const errors: string[] = []
    if (captchaToken === '') errors.push('Please verify you are not a robot')
    if (state.firstName.length === 0) errors.push('First Name cannot be empty')
    if (state.lastName.length === 0) errors.push('Last Name cannot be empty')
    if (state.email.length === 0) errors.push('Email cannot be empty')
    if (state.phone.length === 0) errors.push('Phone cannot be empty')
    if (state.serviceRequestBody.length === 0) errors.push('Please describe what kid of services you\'re looking to get.')
    if (state.serviceTime.length === 0) errors.push('Please tell us how soon you would like service')
    if (state.motorcycleMake.length === 0) errors.push('Make cannot be empty')
    if (state.motorcycleModel.length === 0) errors.push('Model cannot be empty')
    if (state.motorcycleYear.length === 0) errors.push('Bike year cannot be empty')
    if (state.motorcycleMileage.length === 0) errors.push('Mileage cannot be empty')
    if (state.dynoTuning) {
      if (state.dynoExhaust.length === 0) errors.push('Dyno exhaust cannot be empty')
      if (state.dynoIntake.length === 0) errors.push('Dyno intake cannot be empty')
    }
    if (state.priorWork) {
      if (state.priorWorkBody.length === 0) errors.push('Please describe prior work on the bike')
    }
    if (!state.agree) errors.push('Please agree to our policy')


    setValidated(true)
    return errors
  }

  const handleFormSubmit = async (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const errors = validateForm()
    if (errors.length !== 0) {
      setFormErrors(errors)
    } else {
      try {
        setIsLoading(true)
        await server.post('/service-request', state)
        setIsSubmitted(true)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }
  }

  if (isSubmitted) {
    return (
      <div style={{ padding: '2rem', textAlign: 'center'}}>
        <StyledCard>
          <CardContent>
            <Typography variant={'h4'}>Thanks for your interest in having custom work done through our shop! If we have availability, a member of our team will be in touch within 72 hours to discuss scheduling!</Typography>
          </CardContent>
        </StyledCard>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <StyledCard>
        <CardContent>
          <Typography variant='h3'>Custom Service Request</Typography>
          <Typography variant='h6'>We are conveniently located in North Orlando, FL. From oil changes to frame up custom builds, we do it all. Let us know what you’re wanting done!</Typography>

          <form onSubmit={handleFormSubmit} className={classes.form}>
            <Typography variant='h5'>Contact Information</Typography>
            <br/>
            <StyledTextField
              name='firstName'
              label='First Name'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.firstName}
              variant='outlined'
              error={state.firstName === '' && validated}
              helperText={state.firstName === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='lastName'
              label='Last Name'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.lastName}
              variant='outlined'
              error={state.lastName === '' && validated}
              helperText={state.lastName === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='email'
              label='Email'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.email}
              variant='outlined'
              error={state.email === '' && validated}
              helperText={state.email === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='phone'
              label='Phone'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.phone}
              variant='outlined'
              error={state.phone === '' && validated}
              helperText={state.phone === '' && validated ? 'Cannot be empty' : ''}
            />
            <Typography variant='h5'>Bike Details</Typography>
            <StyledTextField
              name='motorcycleMake'
              label='Make'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.motorcycleMake}
              variant='outlined'
              error={state.motorcycleMake === '' && validated}
              helperText={state.motorcycleMake === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='motorcycleModel'
              label='Model'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.motorcycleModel}
              variant='outlined'
              error={state.motorcycleModel === '' && validated}
              helperText={state.motorcycleModel === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='motorcycleYear'
              label='Year'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.motorcycleYear}
              variant='outlined'
              error={state.motorcycleYear === '' && validated}
              helperText={state.motorcycleYear === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='motorcycleMileage'
              label='Mileage'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.motorcycleMileage}
              variant='outlined'
              error={state.motorcycleMileage === '' && validated}
              helperText={state.motorcycleMileage === '' && validated ? 'Cannot be empty' : ''}
            />
            <Typography variant='h5'>Service Details</Typography>
            <StyledTextField
              name='serviceRequestBody'
              label='Service(s) Requested'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.serviceRequestBody}
              variant='outlined'
              multiline
              rows={4}
              error={state.serviceRequestBody === '' && validated}
              helperText={state.serviceRequestBody === '' && validated ? 'Cannot be empty' : ''}
            />
            <Typography variant={'body1'} style={{ marginBottom: 8, textAlign: 'left'}}>When are you looking to get custom work done?</Typography>
            <StyledTextField
              name='serviceTime'
              label='Service Time Frame'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.serviceTime}
              variant='outlined'
              error={state.serviceTime === '' && validated}
              helperText={state.serviceTime === '' && validated ? 'Cannot be empty' : ''}
            />
            <FormControl style={{
              padding: '1rem'
            }}>
              <FormControlLabel
                control={
                  <Checkbox checked={state.dynoTuning} color='primary' onChange={handleCheckBoxChange('dynoTuning')} />
                }
                label={<span style={{fontFamily: 'Teko'}}>I want dyno tuning</span>}
              />
            </FormControl>
            {
              state.dynoTuning && (
                <>
                  <Typography variant={'body1'} style={{ marginBottom: 8, textAlign: 'left'}}>What exhaust is currently installed?</Typography>
                  <StyledTextField
                    name='dynoExhaust'
                    label='Exhaust'
                    fullWidth
                    className={classes.textField}
                    onChange={handleChange}
                    value={state.dynoExhaust}
                    variant='outlined'
                    error={state.dynoExhaust === '' && state.dynoTuning && validated}
                    helperText={state.dynoExhaust === '' && state.dynoTuning  && validated ? 'Cannot be empty' : ''}
                  />
                  <Typography variant={'body1'} style={{ marginBottom: 8, textAlign: 'left'}}>What intake is currently installed?</Typography>
                  <StyledTextField
                    name='dynoIntake'
                    label='Intake'
                    fullWidth
                    className={classes.textField}
                    onChange={handleChange}
                    value={state.dynoIntake}
                    variant='outlined'
                    error={state.dynoIntake === '' && state.dynoTuning  && validated}
                    helperText={state.dynoIntake === '' && state.dynoTuning  && validated ? 'Cannot be empty' : ''}
                  />
                </>
              )
            }

            <FormControl style={{
              padding: '1rem'
            }}>
              <FormControlLabel
                control={
                  <Checkbox checked={state.priorWork} color='primary' onChange={handleCheckBoxChange('priorWork')} />
                }
                label={<Box fontFamily='Teko'>This bike has had prior engine work</Box>}
              />
            </FormControl>
            {
              state.priorWork && (
                <StyledTextField
                  name='priorWorkBody'
                  label='What kind of prior work?'
                  multiline
                  rows={4}
                  fullWidth
                  className={classes.textField}
                  onChange={handleChange}
                  value={state.priorWorkBody}
                  variant='outlined'
                  error={state.priorWorkBody === '' && validated}
                  helperText={state.priorWorkBody === '' && validated ? 'Cannot be empty' : ''}
                />
              )
            }
            <FormControl>
              <ServicePolicy/>
            </FormControl>
            <FormControl style={{
              padding: '1rem'
            }}>
              <FormControlLabel
                control={
                  <Checkbox checked={state.agree} color='primary' onChange={handleCheckBoxChange('agree')} />
                }
                label={<Box fontFamily='Teko'>I agree</Box>}
              />
            </FormControl>
            <br/>
            <FormControl>
              <ReCAPTCHA sitekey={'6LdcDHQcAAAAAEG7eOx_gyMb0VroVtRLH8I9MHlw'} onChange={handleSetToken}/>
            </FormControl>
          </form>
          { formErrors.length > 0 && <Box mt={2}><FormErrorResponse formErrors={formErrors}/></Box> }
          {isLoading && <CircularProgress size={24} className={classes.progress} />}<br />
          <Box my={2}>
            <StyledButton disabled={false} size='large' variant='contained' onClick={handleFormSubmit}>Submit Request</StyledButton>
          </Box>
        </CardContent>
      </StyledCard>
    </div>
  )
}

interface State {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  motorcycleMake: string;
  motorcycleModel: string;
  motorcycleYear: string;
  motorcycleMileage: string;
  serviceRequestBody: string;
  serviceTime: string;
  dynoTuning: boolean;
  dynoExhaust: string;
  dynoIntake: string;
  priorWork: boolean;
  priorWorkBody: string;
  agree: boolean;
}

export default ServiceRequestView
