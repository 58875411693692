import React from 'react'
import {Theme} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/styles'
import CheckoutForm from '../../components/Stripe/CheckoutForm'
import {Elements, StripeProvider} from 'react-stripe-elements'
import {CheckoutStoreProvider} from '../../hooks/useCheckout/context'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    }
  })
)


const AddSubscriptionView: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CheckoutStoreProvider>
        <StripeProvider apiKey={'' + process.env.REACT_APP_STRIPE_KEY}>
          <div>
            <Elements>
              <CheckoutForm />
            </Elements>
          </div>
        </StripeProvider>
      </CheckoutStoreProvider>
    </div>
  )
}

export default AddSubscriptionView
