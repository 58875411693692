import React, {useEffect} from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import {useHistory} from 'react-router-dom'


const ShopView: React.FC = () => {
  const history = useHistory()
  useEffect(() => {
    window.open('https://blockheadmoto.myshopify.com', '_blank')
    history.goBack()
  }, [])

  return (
    <div>
    </div>
  )
}

export default ShopView
