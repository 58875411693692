import React from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import Countdown, {  CountdownTimeDelta } from 'react-countdown-now'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    countdownRender: {
      fontWeight: 400
    }
  })
)

interface Props {
  endDate: string;
}

const CountdownComponent: React.FC<Props> = ({endDate
}) => {
  const classes = useStyles()

  const countdownRenderer = (renderProps: CountdownTimeDelta) => {
    const { days, hours, minutes, seconds, completed } = renderProps
    if (completed) {
      // Render a completed state
      return <span>Giveaway has ended!</span>
    } else {
      // Render a countdown
      return (<span className={classes.countdownRender}>
        <Box letterSpacing={1} fontSize={14} fontStyle={'normal'}>COUNTDOWN</Box>
        <Box>
          <Typography variant='h4'>{days < 10 ? 0 : null }{days} Days Remaining</Typography>
        </Box>
        <Box>
          <Typography variant='h4'>
            {hours < 10 ? 0 : null }{(hours)}:
            {minutes < 10 ? 0 : null }{minutes}:
            {seconds < 10 ? 0 : null }{seconds}
          </Typography>
        </Box>
      </span>)
    }
  }
  console.log(new Date(endDate).toLocaleTimeString('en-US', { timeZone: 'America/Chicago' }))
  return (
    <div className={classes.root}>
      <div>
        <Countdown precision={3} date={new Date(endDate)} renderer={countdownRenderer} zeroPadTime={0} />
      </div>
    </div>
  )
}

export default CountdownComponent
