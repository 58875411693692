import React from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import {Tiers} from '../../data/Tiers'
import PricingComponent from '../../components/PricingComponent/PricingComponent'
import {SubscriptionTier} from '../../types/Subscription'
import {Link} from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import StyledButton from '../../components/StyledButton/StyledButton'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4)
    }
  })
)


const SubscriptionView: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container justify={'center'} style={{ maxWidth: 1000, margin: 'auto' }}>
        <Box fontFamily={'Cubano'} fontWeight={600} fontSize={50}></Box>
        <Box color={'white'} mb={2}>
          <Typography variant='h2'>SUBSCRIPTION PRICING</Typography>
        </Box>
      </Grid>
      <Grid container justify={'center'} style={{ maxWidth: 1400, margin: 'auto' }}>
        {
          Tiers.map((tier: SubscriptionTier) =>
            <Grid item xs={11} md={4} lg={4} key={tier.name}>
              <PricingComponent tier={tier}/>
            </Grid>
          )
        }
        <Grid item xs={12}>
          <Box textAlign={'center'} py={1} color={'gray'}>
            <Link to={'/giveaway'}><StyledButton>Go to Giveaway</StyledButton></Link>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box fontSize={15} textAlign={'center'} py={1} color={'gray'}>
            Subject to <Link to={'/terms-and-conditions'}>Terms and Conditions</Link>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default SubscriptionView
