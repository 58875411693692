import React, {useEffect, useState} from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import {Card, Theme} from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import StyledButton from '../../components/StyledButton/StyledButton'
import AxiosServer from '../../config/server'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import {createStyles, makeStyles} from '@material-ui/styles'
import StyledCard from '../../components/StyledCard/StyledCard'
import CardHeader from '@material-ui/core/CardHeader'
import StyledTextField from '../../components/StyledTextField/StyledTextField'

interface IState {
  token: string;
  email: string;
  newPassword: string;
  newPasswordConfirm: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(4)
    },
    textField: {
      marginBottom: theme.spacing(2)
    }
  })
)

const ForgotPasswordResetView: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const [formState, setFormState] = useState<IState>({
    token: '',
    email: '',
    newPassword: '',
    newPasswordConfirm: ''
  })
  const [responseState, setResponseState] = useState<string>('')
  useEffect(() => {
    const query = queryString.parse(location.search)
    let state = {
      ...formState
    }
    if (query.token) {
      state = {
        ...state,
        token: '' + query.token,
      }
    }
    if (query.email) {
      state = {
        ...state,
        email: '' + query.email,
      }
    }
    setFormState(state)
  }, [])



  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.target.id]: event.target.value
    })
  }


  const handleSubmit = async () => {
    try {
      const { data } = await AxiosServer.post('/auth/forgot-password/verify', formState)
      setResponseState(data.message)
      setTimeout(() => {
        history.push('/login')
      }, 500)
    } catch (e) {
      setResponseState(e.response.data.message)
    }
  }
  return (
    <div className={classes.root}>
      <Grid container justify={'center'}>
        <Grid item xs={12} md={5} lg={4}>
          <StyledCard>
            <CardHeader title={'Update Password'}/>
            <CardContent style={{textAlign: 'center'}}>
              <Box mb={2}>
                <StyledTextField
                  id={'token'}
                  onChange={handleInputChange}
                  fullWidth
                  label={'Token'}
                  variant={'outlined'}
                  value={formState.token}
                  className={classes.textField}
                />
                <StyledTextField
                  id={'email'}
                  onChange={handleInputChange}
                  fullWidth
                  label={'Email'}
                  variant={'outlined'}
                  value={formState.email}
                  className={classes.textField}
                />
                <StyledTextField
                  id={'newPassword'}
                  onChange={handleInputChange}
                  fullWidth
                  label={'New Password'}
                  variant={'outlined'}
                  value={formState.newPassword}
                  className={classes.textField}
                  type={'password'}
                />
                <StyledTextField
                  id={'newPasswordConfirm'}
                  onChange={handleInputChange}
                  fullWidth
                  label={'Confirm New Password'}
                  variant={'outlined'}
                  value={formState.newPasswordConfirm}
                  className={classes.textField}
                  type={'password'}
                />
              </Box>
              {responseState && <Box mb={2}>{ responseState }</Box>}
              <StyledButton variant='contained' onClick={handleSubmit}>Submit</StyledButton>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </div>

  )
}

export default ForgotPasswordResetView
