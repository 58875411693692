// React
import React, {useEffect, useState} from 'react'
import {createStyles, makeStyles} from '@material-ui/styles'
import {Theme} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TitleComponent from '../TextComponents/TitleComponent'
import StyledButton from '../StyledButton/StyledButton'
import {useAuthStore} from '../../hooks/useAuth'
import FormControl from '@material-ui/core/FormControl'
import CountryDropdown from '../../views/Register/CountryDropdown'
import FormHelperText from '@material-ui/core/FormHelperText'
import StateDropdown from '../../views/Register/StateDropdown'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import AxiosServer from '../../config/server'
import CircularProgress from '@material-ui/core/CircularProgress'
import StyledCard from '../StyledCard/StyledCard'
import StyledTextField from '../StyledTextField/StyledTextField'
import CardHeader from '@material-ui/core/CardHeader'

// Router Packages

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    root: {
    },
    formControl: {

    },
    textField: {
      marginBottom: theme.spacing(2)
    },
    progress: {
      marginLeft: theme.spacing(2)
    },
  })
))

interface IState {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  state: string;
  country: string;
}

const EditProfile: React.FC = () => {
  const classes = useStyles()
  const { user, setUser } = useAuthStore()
  const [validated, setValidated] = useState<boolean>(false)
  const [responseState, setResponseState] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formState, setFormState] = useState<IState>({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    state: '',
    country: ''
  })

  useEffect(() => {
    if (user) {
      setFormState({
        email: user.email || '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        phone: user.phone || '',
        state: user.state || '',
        country: user.country || ''
      })
    }
  }, [user])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [event.target.name]: event.target.value })
  }

  const handleSelect = (name: string, value: string | unknown) => {
    setFormState({ ...formState, [name]: value })
  }

  const validateRegister = () => {
    const errors: string[] = []

    if (!formState.firstName) errors.push('First Name cannot be empty')
    if (!formState.lastName) errors.push('Last Name cannot be empty')
    if (!formState.phone) errors.push('Phone cannot be empty')
    if (!formState.email) errors.push('Email cannot be empty')
    if (!formState.country) errors.push('Country cannot be empty')
    if (formState.country === 'United States' && !formState.state) errors.push('State must be selected')
    setValidated(true)
    return errors
  }

  const handleFormSubmit = async (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const errors = validateRegister()
    if (errors.length !== 0) {

    } else {
      setIsLoading(true)
      try {
        const { data } = await AxiosServer.post('/users/profile', formState)
        await setUser(data.user)
        setResponseState(data.message)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
        setResponseState(error.response.data.message)
        setIsLoading(false)
      }
    }
  }

  return (
    <div className={classes.root}>
      <StyledCard>
        <CardContent>
          <form onSubmit={handleFormSubmit}>
            <StyledTextField
              name='email'
              label='Email'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={formState.email}
              variant='outlined'
              error={formState.email === '' && validated}
              helperText={formState.email === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='firstName'
              label='First Name'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={formState.firstName}
              variant='outlined'
              error={formState.firstName === '' && validated}
              helperText={formState.firstName === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='lastName'
              label='Last Name'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={formState.lastName}
              variant='outlined'
              error={formState.lastName === '' && validated}
              helperText={formState.lastName === '' && validated ? 'Cannot be empty' : ''}
            />
            <StyledTextField
              name='phone'
              label='Phone'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={formState.phone}
              variant='outlined'
              error={formState.phone === '' && validated}
              helperText={formState.phone === '' && validated ? 'Cannot be empty' : ''}
            />
            {
              formState.country === 'United States' && (
                <FormControl error={formState.state === '' && validated} variant='outlined' fullWidth className={classes.formControl}>
                  <StateDropdown handleSelect={handleSelect} value={formState.state}/>
                  <FormHelperText>{ formState.state === '' && validated ? 'Cannot be empty' : '' }</FormHelperText>
                </FormControl>
              )
            }
            <FormControl
              variant='outlined'
              fullWidth
              className={classes.formControl}
              error={formState.country === '' && validated}
            >
              <CountryDropdown handleSelect={handleSelect} value={formState.country} validated={validated}/>
              <FormHelperText>{ formState.country === '' && validated ? 'Cannot be empty' : '' }</FormHelperText>
            </FormControl>
            <Box display='flex' alignItems={'center'}>
              <StyledButton variant={'contained'} type='submit'>Submit</StyledButton>
              {isLoading && <CircularProgress size={24} className={classes.progress} />}
              <Box px={2}>
                { responseState }
              </Box>
            </Box>
          </form>
        </CardContent>
      </StyledCard>
    </div>
  )


}

export default EditProfile
