import React from 'react'
import {createStyles, makeStyles} from '@material-ui/styles'
import {Theme} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { terms, termsInstructions } from './TermsOfUse'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'white',
      maxWidth: 1000,
      padding: `0 ${theme.spacing(2)}px`,
      margin: '100px auto',
      '& a' : {
        textDecoration: 'underline'
      }
    },
    title: {
      fontWeight: 800,
      textAlign: 'center',
      marginBottom: theme.spacing(2),
      fontSize: 24
    }
  })
)



const TermsAndConditionsView: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Box textAlign={'center'}>
        <Typography variant={'h3'}>Blockhead Moto Terms Of Use</Typography>
      </Box>

      {
        terms.map((terms) => (
          <Box my={4}>
            <Box mb={2}>
              <Typography variant={'h5'}>{terms.title}</Typography>
            </Box>
            <Box fontSize={14}>
              {terms.body}
            </Box>
          </Box>
        ))
      }
    </div>
  )
}

export default TermsAndConditionsView
