import React from 'react'
import {Grid, Theme} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import FAQComponent from './FAQComponent'
import {createStyles, makeStyles} from '@material-ui/styles'
import TitleComponent from '../../components/TextComponents/TitleComponent'
import Typography from '@material-ui/core/Typography'
import StyledCard from '../../components/StyledCard/StyledCard'
import CardHeader from '@material-ui/core/CardHeader'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),

    },
    details: {

    }
  })
)

const ContactView: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container justify='center'>
        <Grid item xs={12} md={6} lg={4}>
          <StyledCard>
            <CardHeader title='Contact Blockhead'/>
            <CardContent>
              <Typography variant='body1'>
                If you have any questions or having trouble with your account, please contact <a href='mailto:info@blockheadmoto.com'>Info@blockheadmoto.com</a>
              </Typography>
              <Typography variant='body1'>
                Thank you!
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
      <Grid container justify='center'>
        <Grid item xs={12} md={6} lg={4}>
          <Box textAlign={'center'}>
            <Typography variant='h4'>FAQs</Typography>
          </Box>
          <FAQComponent/>
        </Grid>
      </Grid>
    </div>
  )
}

export default ContactView
