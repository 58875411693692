import React, { useEffect, useState } from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Giveaway } from '../../types/Giveaway'
import AxiosServer from '../../config/server'
import GiveawayComponent from './GiveawayComponent'
import ConfirmSubscriptionModal from '../../components/ConfirmSubscriptionModal/ConfirmSubscriptionModal'
import {useLocation} from 'react-router'
import queryString from 'query-string'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import EntriesCard from '../../components/EntriesCard/EntriesCard'
import Typography from '@material-ui/core/Typography'
import {useAuthStore} from '../../hooks/useAuth'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `0 ${theme.spacing(4)}px`
    },
    timer: {
      marginLeft: theme.spacing(1)
    },
    appBar: {
      backgroundColor: 'black',
      boxShadow: 'none',
      fontWeight: 600
    },
    tabs: {

    },
    tab: {
      color: 'white',
      flexGrow: 1,
      fontSize: 30,
      [theme.breakpoints.down('md')]: {
        fontSize: 25
      },
    },
  })
)

const GiveawayView: React.FC = () => {
  const classes = useStyles()
  const { search } = useLocation()
  const { isUserLoggedIn } = useAuthStore()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentGiveaway, setCurrentGiveaway] = useState<Giveaway | null>(null)
  const [showingSubscriptionConfirmModal, setShowingSubscriptionConfirmModal] = useState<boolean>(false)


  useEffect(() => {
    (() => {
      const { subscription } = queryString.parse(search)
      if (subscription === 'approved') {
        setShowingSubscriptionConfirmModal(true)
      }
    })()
  }, [])

  useEffect(() => {
    ( async () => {
      setIsLoading(true)
      try {
        const { data } = await AxiosServer.get('/giveaways/active')
        setCurrentGiveaway(data.data)
        setIsLoading(false)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  let body
  if (!currentGiveaway) {
    body = (
      <Box textAlign={'center'} my={4}>
        <Typography variant={'h4'}>There is no active giveaway right now, check back soon!</Typography>
      </Box>
    )
  } else {
    body =
      <Box mb={4}>
        <GiveawayComponent giveaway={currentGiveaway}/>
      </Box>
  }

  return (
    <div className={classes.root}>
      <ConfirmSubscriptionModal visible={showingSubscriptionConfirmModal} onClose={() => setShowingSubscriptionConfirmModal(false)}/>
      <Grid container justify={'center'} spacing={4}>
        <Grid item xs={12} md={8} lg={9}>
          { isLoading ? <Box textAlign={'center'} py={5}><CircularProgress size={48}/></Box> : body }
        </Grid>
      </Grid>
      {
        isUserLoggedIn && (
          <Grid container justify={'center'} spacing={4}>
            <Grid item xs={12} md={5} lg={4}>
              <EntriesCard/>
            </Grid>
          </Grid>
        )
      }
    </div>
  )
}

export default GiveawayView
